import {
  DropdownOption,
  MonitoringGetInvestorEntitiesMutation,
} from 'interfaces/graphql.types';
import useInvestorEntitiesPickerUtil from 'modules/dashboard/utils/useInvestorEntitiesPicker';
import { createContext, useContext } from 'react';
import { OptionType } from 'renderer';

export const InvestorEntitiesPickerContext = createContext<{
  investorEntity: DropdownOption | undefined;
  investorEntitiesPickerData:
    | MonitoringGetInvestorEntitiesMutation
    | null
    | undefined;
  loadingInvestorEntities: boolean;
  onSelectChangeInvestorEntity: (
    investorEntityOption?: OptionType | null | undefined
  ) => void;
}>({
  investorEntity: undefined,
  investorEntitiesPickerData: undefined,
  loadingInvestorEntities: false,
  onSelectChangeInvestorEntity: () => null,
});

const InvestorEntitiesPickerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    investorEntity,
    investorEntitiesPickerData,
    loadingInvestorEntities,
    onSelectChangeInvestorEntity,
  } = useInvestorEntitiesPickerUtil();

  return (
    <InvestorEntitiesPickerContext.Provider
      value={{
        investorEntity,
        investorEntitiesPickerData,
        loadingInvestorEntities,
        onSelectChangeInvestorEntity,
      }}
    >
      {children}
    </InvestorEntitiesPickerContext.Provider>
  );
};

export const useInvestorEntitiesPicker = () =>
  useContext(InvestorEntitiesPickerContext);

export default InvestorEntitiesPickerProvider;
