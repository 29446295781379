import React from 'react';

interface AppIconProps {
  width?: string;
  height?: string;
  color?: string;
}

export const AppIcon = ({
  width = '24px',
  height = '24px',
  color = '#21B4EF',
}: AppIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.5"
      y="4"
      width="16"
      height="16"
      rx="3"
      stroke={color}
      stroke-width="2"
    />
  </svg>
);
