import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface DocWideProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  color?: string;
  as?: ElementType;
}

export const DocWide = ({
  onClick,
  height = '3.188rem',
  width = '4.375rem',
  color = '#666666',
  as,
}: DocWideProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 54"
    width={width}
    height={height}
    onClick={onClick}
    as={as}
  >
    <path
      fill={color}
      d="M69.5 1h-63a1 1 0 00-1 1v4.5H2a1 1 0 00-1 1v44a1 1 0 001 1h63a1 1 0 000-2H3v-42h2.5V46a1 1 0 001 1h63a1 1 0 001-1V2a1 1 0 00-1-1zm-1 44h-61V3h61z"
    />
    <path
      fill={color}
      d="M13 10.5h49a1 1 0 000-2H13a1 1 0 000 2zM30.4 17H13a.5.5 0 000 1h17.4a.5.5 0 000-1zM34 18h5.4a.5.5 0 000-1H34a.5.5 0 000 1zM51.9 17h-7.4a.5.5 0 000 1h7.4a.5.5 0 100-1zM55 18h5.4a.5.5 0 100-1H55a.5.5 0 000 1zM13 23h15.4a.5.5 0 000-1H13a.5.5 0 000 1zM41.4 22H34a.5.5 0 000 1h7.4a.5.5 0 000-1zM44.5 23h5.4a.5.5 0 100-1h-5.4a.5.5 0 000 1zM62.4 22H55a.5.5 0 000 1h7.4a.5.5 0 100-1zM30.4 27H13a.5.5 0 000 1h17.4a.5.5 0 000-1zM34 28h5.4a.5.5 0 100-1H34a.5.5 0 000 1zM51.9 27h-7.4a.5.5 0 000 1h7.4a.5.5 0 100-1zM55 28h5.4a.5.5 0 100-1H55a.5.5 0 000 1zM28.4 32H13a.5.5 0 000 1h15.4a.5.5 0 000-1zM41.4 32H34a.5.5 0 000 1h7.4a.5.5 0 100-1zM44.5 33h5.4a.5.5 0 100-1h-5.4a.5.5 0 000 1zM62.4 32H55a.5.5 0 000 1h7.4a.5.5 0 100-1zM41.4 39H34a.5.5 0 000 1h7.4a.5.5 0 100-1zM51.9 39h-7.4a.5.5 0 000 1h7.4a.5.5 0 100-1zM62.4 39H55a.5.5 0 000 1h7.4a.5.5 0 100-1z"
    />
  </Svg>
);
