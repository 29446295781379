import styled from 'styled-components';

interface SvgProps {
  shadow?: boolean;
  height: string;
  width: string;
  rotate?: string;
  zIndex?: number | string;
}

interface PathProps {
  variant?: 'primary' | 'secondary' | 'disabled';
}

export const Svg = styled.svg<SvgProps>`
  padding: 0.3rem;
  min-width: ${({ width }) => width};
  z-index: ${({ zIndex }) => zIndex || 1};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  ${({ rotate }) => rotate && `transform: rotate(${rotate}deg)`}
`;

export const Path = styled.path<PathProps>`
  fill: ${({
    theme: {
      colors: { primary, grey },
    },
    variant,
  }) =>
    variant === 'primary'
      ? primary.main
      : variant == 'disabled'
      ? grey[600]
      : grey[300]};
`;
