import { Path, Svg } from './styles';

interface ProfileProps {
  height?: string;
  width?: string;
  strokeWidth?: string;
  color?: string;
  variant?: 'primary' | 'secondary';
}

export const Profile = ({
  height = '3rem',
  width = '3rem',
  strokeWidth = '1',
  color,
  variant,
}: ProfileProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={width}
    height={height}
  >
    <Path
      d="M15 0C6.7 0 0 6.7 0 15C0 23.3 6.7 30 15 30C23.3 30 30 23.3 30 15C30 6.7 23.3 0 15 0ZM15 5.7C17.7 5.7 19.9 7.9 19.9 10.6C19.9 13.3 17.7 15.5 15 15.5C12.3 15.5 10.1 13.3 10.1 10.6C10.1 7.9 12.3 5.7 15 5.7ZM15 28.1C11.1 28.1 7.6 26.5 5.2 23.9C6.8 20.1 10.6 17.4 15 17.4C19.4 17.4 23.2 20.1 24.8 23.9C22.4 26.5 18.9 28.1 15 28.1Z"
      color={color}
      variant={variant}
      strokeWidth={strokeWidth}
      fill="#999999"
    />
  </Svg>
);
