export interface LayoutInterface {
  leftLoggedingMenu?: leftLoggedingMenuArrayType[];
  rightNonLoggMenu?: MenuContent[];
  isAdmin?: boolean;
  isSalesRep?: boolean;
  variant?: VariantTypes;
  adminIsAuthenticated?: boolean;
  title?: string;
}

interface MenuContent {
  link: string;
  label: string;
}

interface leftLoggedingMenuTypes {
  color: string;
  icon: string;
  dataCy?: string;
  logoutLink?: boolean;
  hasWebShopping?: boolean;
}

type leftLoggedingMenuArrayType = leftLoggedingMenuTypes & MenuContent;

export type ButtonSize = 'large' | 'small' | 'standard' | 'custom';
export type StyledButtonVariant = 'primary' | 'secondary' | 'tertiary';

export enum VariantTypes {
  SECONDARY = 'secondary',
  IS_ACTIVE = 'is-active',
}
