import { Path, Svg } from './styles';

interface AnalyticProps {
  height?: string;
  width?: string;
  strokeWidth?: string;
  color?: string;
  variant?: 'primary' | 'secondary';
}

export const Analytic = ({
  height = '2rem',
  width = '3rem',
  strokeWidth = '1',
  color,
  variant,
}: AnalyticProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width={width}
    height={height}
  >
    <Path
      d="M32.2 0C29.6745 0 27.6902 1.98431 27.6902 4.5098C27.6902 5.59216 28.051 6.58431 28.6824 7.30588L24.0824 14.251C23.7216 14.1608 23.2706 14.0706 22.8196 14.0706C22.4588 14.0706 22.0078 14.1608 21.6471 14.251L17.3176 7.39608C17.949 6.58431 18.4 5.59216 18.4 4.5098C18.4 1.98431 16.4157 0 13.8902 0C11.3647 0 9.38039 1.98431 9.38039 4.5098C9.38039 5.59216 9.74118 6.58431 10.3725 7.30588L5.77255 14.251C5.41176 14.1608 4.96078 14.0706 4.5098 14.0706C1.98431 14.0706 0 16.0549 0 18.5804C0 21.1059 1.98431 23.0902 4.5098 23.0902C7.03529 23.0902 9.01961 21.1059 9.01961 18.5804C9.01961 17.498 8.65882 16.5059 8.02745 15.7843L12.7176 8.83922C13.0784 8.92941 13.5294 9.01961 13.9804 9.01961C14.3412 9.01961 14.7922 8.92941 15.1529 8.83922L19.4824 15.6941C18.851 16.5059 18.4 17.498 18.4 18.5804C18.4 21.1059 20.3843 23.0902 22.9098 23.0902C25.4353 23.0902 27.4196 21.1059 27.4196 18.5804C27.4196 17.498 27.0588 16.5059 26.4275 15.7843L31.0274 8.83922C31.3882 8.92941 31.8392 9.01961 32.2902 9.01961C34.8157 9.01961 36.8 7.03529 36.8 4.5098C36.8 1.98431 34.7255 0 32.2 0Z"
      fill={color}
      variant={variant}
      strokeWidth={strokeWidth}
    />
  </Svg>
);
