import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface LockProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  color?: string;
  as?: ElementType;
}

export const Lock = ({
  onClick,
  height = '2.188rem',
  width = '3.375rem',
  color = '#666666',
  as,
}: LockProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 37"
    width={width}
    height={height}
    onClick={onClick}
    as={as}
  >
    <path
      fill={color}
      d="M29 14.23h-4.83v-5a8.17 8.17 0 10-16.34 0v5H3a2 2 0 00-2 2V34a2 2 0 002 2h26a2 2 0 002-2V16.24a2 2 0 00-2-2.01zM10.83 9.17a5.17 5.17 0 0110.34 0v5H10.83z"
    />
  </Svg>
);
