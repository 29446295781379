import { ElementType, MouseEvent } from 'react';

interface PenProps {
  style?: Record<string, unknown>;
  onClick?: (e: MouseEvent) => void;
  color?: string;
  height?: string;
  width?: string;
  inactive?: boolean;
  as?: ElementType;
}

export const Info = ({ onClick, height = '20', width = '20' }: PenProps) => (
  <svg
    onClick={onClick}
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="9.5" stroke="#21B4EF" />
    <path
      d="M11.874 14.0225L11.7236 14.6377C11.2725 14.8154 10.9124 14.9499 10.6436 15.041C10.3747 15.1367 10.0625 15.1846 9.70703 15.1846C9.16016 15.1846 8.73405 15.0524 8.42871 14.7881C8.12793 14.5192 7.97754 14.1797 7.97754 13.7695C7.97754 13.61 7.98893 13.4482 8.01172 13.2842C8.03451 13.1156 8.07096 12.9264 8.12109 12.7168L8.68164 10.7207C8.73177 10.5293 8.77279 10.3493 8.80469 10.1807C8.84115 10.0075 8.85938 9.85026 8.85938 9.70898C8.85938 9.45378 8.80697 9.27604 8.70215 9.17578C8.59733 9.07552 8.39681 9.02539 8.10059 9.02539C7.95475 9.02539 7.80436 9.04818 7.64941 9.09375C7.49902 9.13932 7.36914 9.18262 7.25977 9.22363L7.41016 8.6084C7.7793 8.45801 8.13249 8.3304 8.46973 8.22559C8.80697 8.11621 9.12598 8.06152 9.42676 8.06152C9.96908 8.06152 10.3861 8.19368 10.6777 8.45801C10.974 8.71777 11.1221 9.05729 11.1221 9.47656C11.1221 9.56315 11.1107 9.71582 11.0879 9.93457C11.0697 10.1533 11.0332 10.3538 10.9785 10.5361L10.418 12.5254C10.3724 12.6849 10.3314 12.8672 10.2949 13.0723C10.2585 13.2773 10.2402 13.4323 10.2402 13.5371C10.2402 13.8014 10.2995 13.9814 10.418 14.0771C10.5365 14.1729 10.7415 14.2207 11.0332 14.2207C11.1699 14.2207 11.3249 14.1979 11.498 14.1523C11.6712 14.1022 11.7965 14.0589 11.874 14.0225ZM12.0176 5.67578C12.0176 6.02214 11.8854 6.31836 11.6211 6.56445C11.3613 6.80599 11.0469 6.92676 10.6777 6.92676C10.3086 6.92676 9.99186 6.80599 9.72754 6.56445C9.46322 6.31836 9.33105 6.02214 9.33105 5.67578C9.33105 5.32943 9.46322 5.0332 9.72754 4.78711C9.99186 4.54102 10.3086 4.41797 10.6777 4.41797C11.0469 4.41797 11.3613 4.54102 11.6211 4.78711C11.8854 5.0332 12.0176 5.32943 12.0176 5.67578Z"
      fill="#21B4EF"
    />
  </svg>
);
