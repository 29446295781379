import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface DollarProps {
  onClick?: (e: MouseEvent) => void;
  color?: string;
  height?: string;
  width?: string;
  strokeWidth?: string;
  inactive?: boolean;
  as?: ElementType;
}

export const Dollar = ({
  onClick,
  height = '1.25rem',
  width = '1.25rem',
  as,
}: DollarProps) => (
  <Svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width={width}
    height={height}
    as={as}
  >
    <defs>
      <path d="M4.5.5h13v21h-13V.5z" />
    </defs>
    <path d="M12 12.08v5c1.76-.12 2.81-1 2.81-2.53s-1.12-2-2.81-2.45zM10.65 9V4.41C9.21 4.48 8 5.09 8 6.59S9 8.52 10.65 9zm0 12.55v-2.18a7.2 7.2 0 01-6.15-3.63l2.23-1.26a5 5 0 003.92 2.58v-5.28c-2.35-.53-5.38-1.56-5.38-4.91 0-2.87 2.21-4.64 5.38-4.77V.5H12v1.65a7.12 7.12 0 015.14 3l-2 1.3A5.05 5.05 0 0012 4.51v4.76c2.66.65 5.46 1.78 5.46 5 0 2.73-2.06 4.79-5.46 5.09v2.14z" />
  </Svg>
);
