import { VariantTypes } from 'ui/uiTypes';

interface ThemeProps {
  theme: {
    colors: {
      black: { [key: string]: string };
      grey: { [key: string]: string };
    };
  };
  variant?: VariantTypes;
}

export const getVariantColor =
  () =>
  ({ theme, variant }: ThemeProps) => {
    switch (variant) {
      case VariantTypes.SECONDARY:
        return `
				color: ${theme.colors.grey[0]};
		`;
      case VariantTypes.IS_ACTIVE:
        return `
				color: ${theme.colors.black[300]};
				text-transform: uppercase;
		`;
      default:
        return `
				color: ${theme.colors.grey[400]};
				text-transform: uppercase;
		`;
    }
  };

export const getVariantBgColor =
  () =>
  ({ theme, variant }: ThemeProps) => {
    switch (variant) {
      case VariantTypes.SECONDARY:
        return `
				background-color: ${theme.colors.grey[600]};
		`;
      default:
        return `
				background-color: ${theme.colors.grey[0]};
		`;
    }
  };

export const getVariantSvg =
  () =>
  ({ theme, variant }: ThemeProps) => {
    switch (variant) {
      case VariantTypes.SECONDARY:
        return `
				fill: ${theme.colors.grey[0]};
				stroke: ${theme.colors.grey[0]};
		`;
      default:
        return `
			fill: ${theme.colors.black[300]};
			stroke: ${theme.colors.black[300]};
	`;
    }
  };
