import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface PlusCircleProps {
  style?: Record<string, unknown>;
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  color?: string;
  as?: ElementType;
  fillCircle?: string;
  fillPlus?: string;
}

export const PlusCircle = ({
  style,
  onClick,
  height = '1.25rem',
  width = '1.25rem',
  as,
  fillCircle,
  fillPlus,
}: PlusCircleProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    {...{ fillPlus, width, height, onClick, style, as, fillCircle }}
  >
    <path d="M11.91 5h-1.82v5.09H5v1.82h5.09V17h1.82v-5.09H17v-1.82h-5.09V5z" />
    <path d="M11 .5A10.5 10.5 0 1021.5 11 10.51 10.51 0 0011 .5zm0 20a9.5 9.5 0 119.5-9.5 9.51 9.51 0 01-9.5 9.5z" />
  </Svg>
);
