import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface AscendingProps {
  style?: Record<string, unknown>;
  onClick?: (e: MouseEvent) => void | undefined;
  color?: string;
  height?: string;
  width?: string;
  strokeWidth?: string;
  inactive?: boolean;
  as?: ElementType;
}

export const Ascending = ({
  onClick,
  height = '31',
  width = '31',
  inactive = false,
  as,
}: AscendingProps) => (
  <Svg
    inactive={inactive}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31 31"
    width={width}
    height={height}
    as={as}
  >
    <mask
      id="ascending0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="31"
      height="31"
    >
      <circle cx="15.5" cy="15.5" r="15.5" fill="#C4C4C4" />
    </mask>
    <g mask="url(#ascending0)">
      <rect width="40" height="40" transform="translate(-5 -5)" fill="white" />
      <rect x="-5" y="-5" width="40" height="40" fill="#F3F3F5" />
      <mask
        id="ascending1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="9"
        y="11"
        width="13"
        height="9"
      >
        <path
          d="M15.55 11.45L21.95 17.85L20.55 19.25L15.55 14.35L10.65 19.25L9.25 17.85L15.55 11.45Z"
          fill="black"
        />
      </mask>
      <g mask="url(#ascending1)">
        <rect
          x="-4.75"
          y="35.25"
          width="40"
          height="40"
          transform="rotate(-90 -4.75 35.25)"
          fill="#21B4EF"
        />
      </g>
    </g>
  </Svg>
);
