import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface CameraIconProps {
  onClick?: (e: MouseEvent) => void;
  color?: string;
  height?: string;
  width?: string;
  strokeWidth?: string;
  inactive?: boolean;
  as?: ElementType;
}

export const CameraIcon = ({
  onClick,
  height = '3.2rem',
  width = '3.2rem',
  color = '#AAAAAA',
  as,
}: CameraIconProps) => (
  <Svg
    as={as}
    height={height}
    width={width}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 36"
  >
    <path
      stroke={color}
      d="M48.33,4.67H35.51l-.64-2.08A3.66,3.66,0,0,0,31.36,0H20.64a3.66,3.66,0,0,0-3.51,2.59l-.64,2.08H13.22V4A3.67,3.67,0,0,0,9.56.33H8.22A3.67,3.67,0,0,0,4.56,4v.67H3.67A3.66,3.66,0,0,0,0,8.33v24A3.67,3.67,0,0,0,3.67,36H48.33A3.67,3.67,0,0,0,52,32.33v-24A3.66,3.66,0,0,0,48.33,4.67ZM6.56,4A1.66,1.66,0,0,1,8.22,2.33H9.56A1.67,1.67,0,0,1,11.22,4v.67H6.56ZM50,32.33A1.67,1.67,0,0,1,48.33,34H3.67A1.67,1.67,0,0,1,2,32.33v-24A1.66,1.66,0,0,1,3.67,6.67H17.23a1,1,0,0,0,1-.71L19,3.18A1.66,1.66,0,0,1,20.64,2H31.36A1.66,1.66,0,0,1,33,3.18L33.81,6a1,1,0,0,0,1,.71H48.33A1.66,1.66,0,0,1,50,8.33Z"
    />
    <path
      stroke={color}
      d="M26,10.83a10,10,0,1,0,10,10A10,10,0,0,0,26,10.83Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,26,28.83Z"
    />
  </Svg>
);
