import React, { MouseEvent } from 'react';
import theme from 'ui/theme/config';

import { Svg } from './styles';

interface MyPerformanceIconProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  color?: string;
  as?: React.ElementType;
}

export const MyPerformanceIcon = ({
  onClick,
  height = '24',
  width = '24',
  color = theme.colors.icons.blue,
  as,
}: MyPerformanceIconProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    as={as}
    onClick={onClick}
  >
    <path
      d="M9 12C7.9 12 6.95833 11.6083 6.175 10.825C5.39167 10.0417 5 9.1 5 8C5 6.9 5.39167 5.95833 6.175 5.175C6.95833 4.39167 7.9 4 9 4C10.1 4 11.0417 4.39167 11.825 5.175C12.6083 5.95833 13 6.9 13 8C13 9.1 12.6083 10.0417 11.825 10.825C11.0417 11.6083 10.1 12 9 12ZM1 18V17.2C1 16.65 1.14167 16.1333 1.425 15.65C1.70833 15.1667 2.1 14.8 2.6 14.55C3.45 14.1167 4.40833 13.75 5.475 13.45C6.54167 13.15 7.71667 13 9 13H9.35C9.45 13 9.55 13.0167 9.65 13.05C9.51667 13.35 9.40417 13.6625 9.3125 13.9875C9.22083 14.3125 9.15 14.65 9.1 15H9C7.81667 15 6.75417 15.15 5.8125 15.45C4.87083 15.75 4.1 16.05 3.5 16.35C3.35 16.4333 3.22917 16.55 3.1375 16.7C3.04583 16.85 3 17.0167 3 17.2V18H9.3C9.4 18.35 9.53333 18.6958 9.7 19.0375C9.86667 19.3792 10.05 19.7 10.25 20H3C2.45 20 1.97917 19.8042 1.5875 19.4125C1.19583 19.0208 1 18.55 1 18ZM9 10C9.55 10 10.0208 9.80417 10.4125 9.4125C10.8042 9.02083 11 8.55 11 8C11 7.45 10.8042 6.97917 10.4125 6.5875C10.0208 6.19583 9.55 6 9 6C8.45 6 7.97917 6.19583 7.5875 6.5875C7.19583 6.97917 7 7.45 7 8C7 8.55 7.19583 9.02083 7.5875 9.4125C7.97917 9.80417 8.45 10 9 10Z"
      fill={color}
    />
    <path
      d="M12.5442 21C12.3953 21 12.2674 20.9487 12.1604 20.846C12.0535 20.7433 12 20.6161 12 20.4643V14.75C12 14.5982 12.0527 14.471 12.1581 14.3683C12.2635 14.2656 12.3942 14.2143 12.55 14.2143H14.3892C14.5381 14.2143 14.666 14.2656 14.7729 14.3683C14.8799 14.471 14.9333 14.5982 14.9333 14.75V20.4643C14.9333 20.6161 14.8806 20.7433 14.7752 20.846C14.6698 20.9487 14.5392 21 14.3833 21H12.5442ZM16.5775 21C16.4286 21 16.3007 20.9487 16.1937 20.846C16.0868 20.7433 16.0333 20.6161 16.0333 20.4643V11.5357C16.0333 11.3839 16.086 11.2567 16.1915 11.154C16.2969 11.0513 16.4275 11 16.5833 11H18.4225C18.5714 11 18.6993 11.0513 18.8063 11.154C18.9132 11.2567 18.9667 11.3839 18.9667 11.5357V20.4643C18.9667 20.6161 18.914 20.7433 18.8085 20.846C18.7031 20.9487 18.5725 21 18.4167 21H16.5775ZM20.6108 21C20.4619 21 20.334 20.9487 20.2271 20.846C20.1201 20.7433 20.0667 20.6161 20.0667 20.4643V15.8214C20.0667 15.6696 20.1194 15.5424 20.2248 15.4397C20.3302 15.3371 20.4608 15.2857 20.6167 15.2857H22.4558C22.6047 15.2857 22.7326 15.3371 22.8396 15.4397C22.9465 15.5424 23 15.6696 23 15.8214V20.4643C23 20.6161 22.9473 20.7433 22.8419 20.846C22.7365 20.9487 22.6058 21 22.45 21H20.6108Z"
      fill={color}
    />
  </Svg>
);
