import APP_VERSION from './version.json';

enum CLIENT_NAME {
  relevant = 'Relevant',
  core = 'CORE Industrial Partners',
  metro = 'Metropolitan Realty Group',
  solyco = 'Solyco Capital',
  jvm = 'JVM Realty Corporation',
  rmr = 'The RMR Group',
  wellspring = 'Wellspring Captial Management',
}

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
const API_ENDPOINT = process.env.API_ENDPOINT || '/graphql';
const ENV = process.env.NODE_ENV;
const COMPLIANCE_DOC_LINK = '/settings/invest-person/compliance-doc';
const PERSON_URL = '/settings/person/';
const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || '';
const RECAPTCHA_INVISIBLE =
  process.env.NEXT_PUBLIC_RECAPTCHA_INVISIBLE == 'false' ? false : true;
const RECAPTCHA_ENABLED = process.env.NEXT_PUBLIC_RECAPTCHA_ENABLED
  ? process.env.NEXT_PUBLIC_RECAPTCHA_ENABLED === 'true'
  : true;
const CLOUDFLARE_PDF_JS = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/';
const JWT_ACCESS_TOKEN_EXPIRES_IN = process.env
  .NEXT_PUBLIC_JWT_ACCESS_TOKEN_EXPIRES_IN
  ? parseInt(process.env.NEXT_PUBLIC_JWT_ACCESS_TOKEN_EXPIRES_IN)
  : 60 * 15;
const MVP_ENABLED =
  process.env.NEXT_PUBLIC_MVP_ENABLED === 'true' ? true : false;
const CLIENT = process.env.NEXT_PUBLIC_CLIENT || 'relevant';
const CLIENTS = process.env.NEXT_PUBLIC_CLIENTS
  ? process.env.NEXT_PUBLIC_CLIENTS.split(', ')
  : [
      'relevant',
      'core',
      'jvm',
      'metro',
      'origdevdata',
      'solyco',
      'rmr',
      'celtic house',
      'wellspring',
      'eval',
    ];
const SANDBOX = process.env.NEXT_PUBLIC_SANDBOX || '';

const CLIENT_HOMEPAGE = process.env.NEXT_PUBLIC_CLIENT_HOMEPAGE || '/';
const MAPBOX_KEY = process.env.NEXT_PUBLIC_MAPBOX_KEY;
const PUBLIC_IMAGE_BUCKET =
  process.env.NEXT_PUBLIC_IMAGE_BUCKET || 'relevant-public';
export {
  API_ENDPOINT,
  APP_VERSION,
  BASE_URL,
  CLIENT,
  CLIENT_HOMEPAGE,
  CLIENT_NAME,
  CLIENTS,
  CLOUDFLARE_PDF_JS,
  COMPLIANCE_DOC_LINK,
  ENV,
  JWT_ACCESS_TOKEN_EXPIRES_IN,
  MAPBOX_KEY,
  MVP_ENABLED,
  PERSON_URL,
  PUBLIC_IMAGE_BUCKET,
  RECAPTCHA_ENABLED,
  RECAPTCHA_INVISIBLE,
  RECAPTCHA_SITE_KEY,
  SANDBOX,
};
