import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface InvestIconProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  as?: ElementType;
}

export const InvestIcon = ({
  onClick,
  height = '1.625rem',
  width = '1.625rem',
  as,
}: InvestIconProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.5 23.5"
    width={width}
    height={height}
    onClick={onClick}
    as={as}
  >
    <path d="M24.75 22h-24a.75.75 0 000 1.5h24a.75.75 0 000-1.5zM2.25 20h5a.76.76 0 00.75-.75v-7.67a.76.76 0 00-.75-.75h-5a.76.76 0 00-.75.75v7.67a.76.76 0 00.75.75zM3 12.33h3.5v6.17H3zM10.75 20h5a.76.76 0 00.75-.75V.75a.76.76 0 00-.75-.75h-5a.76.76 0 00-.75.75v18.5a.76.76 0 00.75.75zm.75-18.5H15v17h-3.5zM18.75 20h5a.76.76 0 00.75-.75V5.75a.76.76 0 00-.75-.75h-5a.76.76 0 00-.75.75v13.5a.76.76 0 00.75.75zm.75-13.5H23v12h-3.5z" />
  </Svg>
);
