import {
  DropdownOption,
  MonitoringGetInvInteriorPageHeaderMenuMutation,
} from 'interfaces/graphql.types';
import useInvPageHeaderMenuPickerUtil from 'modules/dashboard/utils/useInvPageHeaderMenuPicker';
import { createContext, useContext } from 'react';
import { OptionType } from 'renderer';

export const InvPageHeaderMenuContext = createContext<{
  pageHeaderMenu: DropdownOption | undefined;
  pageHeaderMenuData:
    | MonitoringGetInvInteriorPageHeaderMenuMutation
    | null
    | undefined;
  loadingPageHeaderMenu: boolean;
  onSelectChangePageHeaderMenu: (
    pageHeaderMenuOption?: OptionType | null | undefined
  ) => void;
}>({
  pageHeaderMenu: undefined,
  pageHeaderMenuData: undefined,
  loadingPageHeaderMenu: false,
  onSelectChangePageHeaderMenu: () => null,
});

const InvPageHeaderMenuProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    pageHeaderMenu,
    pageHeaderMenuData,
    loadingPageHeaderMenu,
    onSelectChangePageHeaderMenu,
  } = useInvPageHeaderMenuPickerUtil();

  return (
    <InvPageHeaderMenuContext.Provider
      value={{
        pageHeaderMenu,
        pageHeaderMenuData,
        loadingPageHeaderMenu,
        onSelectChangePageHeaderMenu,
      }}
    >
      {children}
    </InvPageHeaderMenuContext.Provider>
  );
};

export const useInvPageHeaderMenuPicker = () =>
  useContext(InvPageHeaderMenuContext);

export default InvPageHeaderMenuProvider;
