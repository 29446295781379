import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface SortProps {
  style?: Record<string, unknown>;
  onClick?: (e: MouseEvent) => void;
  color?: string;
  height?: string;
  width?: string;
  strokeWidth?: string;
  inactive?: boolean;
  as?: ElementType;
}

export const Sort = ({
  onClick,
  height = '1.25rem',
  width = '1.25rem',
  strokeWidth = '1',
  inactive = false,
  as,
}: SortProps) => (
  <Svg
    inactive={inactive}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width={width}
    height={height}
    as={as}
  >
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M19 1H3a2 2 0 00-2 2v16a2 2 0 002 2h16a2 2 0 002-2V3a2 2 0 00-2-2zm-8 17l-3-5h6.11zM7.92 9L11 4l3.07 5z"
      fillRule="evenodd"
    />
  </Svg>
);
