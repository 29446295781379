import * as React from 'react';

import { CopySvg } from './style';

interface CopyProps {
  onClick?: VoidFunction;
}

export const Copy = ({ onClick }: CopyProps) => (
  <CopySvg width={16} height={22} fill="none" {...{ onClick }}>
    <path
      d="M3.2 0v18.578H16V0H3.2zm1.829 16.622V1.956h9.142v14.666H5.03z"
      fill="#424249"
    />
    <path d="M1.829 3.911H0V22h12.8v-1.956H1.829V3.912z" fill="#424249" />
  </CopySvg>
);
