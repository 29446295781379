const JVMNextShieldConfig = {
  privateRoutes: [
    '/dashboard',
    '/offering/[id]/investments',
    '/documents',
    '/tax-center',
    '/settings',
    '/settings/[field]',
  ],
  publicRoutes: [
    '/auth/signin',
    'auth/forgot-password',
    '/auth/2-fa',
    '/auth/2-fa-reset-password',
    '/auth/reset-password',
    '/auth/forgot-password',
    '/auth/forgot-password-email-check',
    '/auth/onboarding',
    '/auth/onboarding-password',
    '/auth/2-fa-onboarding',
    '/api/v1/file/download/*',
  ],
  hybridRoutes: ['/'],
  loginRoute: '/auth/signin',
  RBAC: {
    USER: {
      grantedRoutes: [
        '/dashboard',
        '/offering/[id]/investments',
        '/documents',
        '/tax-center',
        '/auth/signin',
        '/auth/2-fa',
        '/settings',
        '/settings/[field]',
        '/auth/2-fa',
        '/auth/2-fa-reset-password',
        '/auth/forgot-password',
        '/auth/forgot-password-email-check',
      ],
      accessRoute: '/dashboard',
    },
  },
};

const SolycoNextShieldConfig = {
  privateRoutes: [
    '/salesrep',
    '/salesrep/investors',
    '/salesrep/investors/details/[id]',
    '/salesrep/profile',
    '/pdf/[...ids]',
    '/dashboard',
    '/documents',
    '/tax-center',
    '/settings',
    '/admin',
    '/salesrep',
    '/offering/[id]/investments',
  ],
  publicRoutes: [
    '/auth/signin',
    '/auth/forgot-password',
    '/auth/2-fa',
    '/auth/2-fa-reset-password',
    '/auth/reset-password',
    '/auth/forgot-password',
    '/auth/forgot-password-email-check',
    '/auth/onboarding',
    '/auth/onboarding-password',
    '/auth/2-fa-onboarding',
    '/api/v1/file/download/*',
    '/auth/signin',
    'auth/forgot-password',
    '/auth/2-fa',
    '/auth/2-fa-reset-password',
    '/auth/reset-password',
    '/auth/forgot-password',
    '/auth/forgot-password-email-check',
    '/auth/onboarding',
    '/auth/onboarding-password',
    '/auth/2-fa-onboarding',
    '/api/v1/file/download/*',
  ],
  hybridRoutes: ['/'],
  loginRoute: '/auth/signin',
  RBAC: {
    USER: {
      grantedRoutes: [
        '/auth/signin',
        '/auth/2-fa',
        '/auth/2-fa',
        '/auth/2-fa-reset-password',
        '/auth/forgot-password',
        '/auth/forgot-password-email-check',
        '/salesrep',
        '/salesrep/investors',
        '/salesrep/investors/details/[id]',
        '/salesrep/profile',
        '/pdf/[...ids]',
        '/dashboard',
        '/offering/[id]/investments',
        '/documents',
        '/tax-center',
        '/auth/signin',
        '/auth/2-fa',
        '/settings',
        '/settings/[field]',
        '/auth/2-fa',
        '/auth/2-fa-reset-password',
        '/auth/forgot-password',
        '/auth/forgot-password-email-check',
      ],
      accessRoute: '/salesrep',
    },
  },
};

const NextShieldConfig = {
  privateRoutes: [
    '/dashboard',
    '/documents',
    '/tax-center',
    '/settings',
    '/admin',
    '/salesrep',
    '/offering/[id]/investments',
    '/settings/[field]',
  ],
  publicRoutes: [
    '/auth/signin',
    'auth/forgot-password',
    '/auth/2-fa',
    '/auth/2-fa-reset-password',
    '/auth/reset-password',
    '/auth/forgot-password',
    '/auth/forgot-password-email-check',
    '/auth/onboarding',
    '/auth/onboarding-password',
    '/auth/2-fa-onboarding',
    '/api/v1/file/download/*',
  ],
  hybridRoutes: ['/', '/admin/*'],
  loginRoute: '/auth/signin',
  RBAC: {
    USER: {
      grantedRoutes: [
        '/dashboard',
        '/offering/[id]/investments',
        '/documents',
        '/tax-center',
        '/auth/signin',
        '/auth/2-fa',
        '/settings',
        '/settings/[field]',
        '/auth/2-fa',
        '/auth/2-fa-reset-password',
        '/auth/forgot-password',
        '/auth/forgot-password-email-check',
      ],
      accessRoute: '/',
    },
  },
};

const CoreNextShieldConfig = {
  privateRoutes: [
    '/dashboard',
    '/offering/[id]/investments',
    '/documents',
    '/tax-center',
    '/settings',
    '/settings/[field]',
  ],
  publicRoutes: [
    '/auth/signin',
    'auth/forgot-password',
    '/auth/2-fa',
    '/auth/2-fa-reset-password',
    '/auth/reset-password',
    '/auth/forgot-password',
    '/auth/forgot-password-email-check',
    '/auth/onboarding',
    '/auth/onboarding-password',
    '/auth/2-fa-onboarding',
    '/api/v1/file/download/*',
  ],
  hybridRoutes: ['/'],
  loginRoute: '/auth/signin',
  RBAC: {
    USER: {
      grantedRoutes: [
        '/dashboard',
        '/offering/[id]/investments',
        '/documents',
        '/tax-center',
        '/auth/signin',
        '/auth/2-fa',
        '/settings',
        '/settings/[field]',
        '/auth/2-fa',
        '/auth/2-fa-reset-password',
        '/auth/forgot-password',
        '/auth/forgot-password-email-check',
      ],
      accessRoute: '/dashboard',
    },
  },
};

const MetroNextShieldConfig = {
  privateRoutes: [
    '/dashboard',
    '/offering/[id]/investments',
    '/documents',
    '/tax-center',
    '/settings',
    '/settings/[field]',
  ],
  publicRoutes: [
    '/auth/signin',
    'auth/forgot-password',
    '/auth/2-fa',
    '/auth/2-fa-reset-password',
    '/auth/reset-password',
    '/auth/forgot-password',
    '/auth/forgot-password-email-check',
    '/auth/onboarding',
    '/auth/onboarding-password',
    '/auth/2-fa-onboarding',
    '/api/v1/file/download/*',
  ],
  hybridRoutes: ['/'],
  loginRoute: '/auth/signin',
  RBAC: {
    USER: {
      grantedRoutes: [
        '/dashboard',
        '/offering/[id]/investments',
        '/documents',
        '/tax-center',
        '/auth/signin',
        '/auth/2-fa',
        '/settings',
        '/settings/[field]',
        '/auth/2-fa',
        '/auth/2-fa-reset-password',
        '/auth/forgot-password',
        '/auth/forgot-password-email-check',
      ],
      accessRoute: '/dashboard',
    },
  },
};

const RMRNextShieldConfig = {
  privateRoutes: [
    '/dashboard',
    '/offering/[id]/investments',
    '/documents',
    '/settings',
    '/settings/[field]',
  ],
  publicRoutes: [
    '/auth/signin',
    'auth/forgot-password',
    '/auth/2-fa',
    '/auth/2-fa-reset-password',
    '/auth/reset-password',
    '/auth/forgot-password',
    '/auth/forgot-password-email-check',
    '/auth/onboarding',
    '/auth/onboarding-password',
    '/auth/2-fa-onboarding',
    '/api/v1/file/download/*',
  ],
  hybridRoutes: ['/'],
  loginRoute: '/auth/signin',
  RBAC: {
    USER: {
      grantedRoutes: [
        '/dashboard',
        '/offering/[id]/investments',
        '/documents',
        '/auth/signin',
        '/auth/2-fa',
        '/settings',
        '/settings/[field]',
        '/auth/2-fa',
        '/auth/2-fa-reset-password',
        '/auth/forgot-password',
        '/auth/forgot-password-email-check',
      ],
      accessRoute: '/dashboard',
    },
  },
};

const WellspringNextShieldConfig = {
  privateRoutes: [
    '/dashboard',
    '/offering/[id]/investments',
    '/documents',
    '/settings',
    '/settings/[field]',
  ],
  publicRoutes: [
    '/auth/signin',
    'auth/forgot-password',
    '/auth/2-fa',
    '/auth/2-fa-reset-password',
    '/auth/reset-password',
    '/auth/forgot-password',
    '/auth/forgot-password-email-check',
    '/auth/onboarding',
    '/auth/onboarding-password',
    '/auth/2-fa-onboarding',
    '/api/v1/file/download/*',
  ],
  hybridRoutes: ['/'],
  loginRoute: '/auth/signin',
  RBAC: {
    USER: {
      grantedRoutes: [
        '/dashboard',
        '/offering/[id]/investments',
        '/documents',
        '/auth/signin',
        '/auth/2-fa',
        '/settings',
        '/settings/[field]',
        '/auth/2-fa',
        '/auth/2-fa-reset-password',
        '/auth/forgot-password',
        '/auth/forgot-password-email-check',
      ],
      accessRoute: '/dashboard',
    },
  },
};

export {
  CoreNextShieldConfig,
  JVMNextShieldConfig,
  MetroNextShieldConfig,
  NextShieldConfig,
  RMRNextShieldConfig,
  SolycoNextShieldConfig,
  WellspringNextShieldConfig,
};
