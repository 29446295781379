import styled from 'styled-components';
import { getVariantSvg } from 'ui/theme/helpers';

interface SvgProps {
  height: string;
  width: string;
}

export const Svg = styled.svg<SvgProps>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

export const Path = styled.path`
  ${getVariantSvg()}
`;
