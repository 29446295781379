import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface PlusProps {
  style?: Record<string, unknown>;
  onClick?: (e: MouseEvent) => void;
  color?: string;
  height?: string;
  width?: string;
  strokeWidth?: string;
  inactive?: boolean;
  as?: ElementType;
}

export const Plus = ({
  onClick,
  height = '1.25rem',
  width = '1.25rem',
  strokeWidth = '1',
  inactive = false,
  as,
}: PlusProps) => (
  <Svg
    data-testid="plus-icon-id"
    inactive={inactive}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width={width}
    height={height}
    as={as}
  >
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M19,1H3A2,2,0,0,0,1,3V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V3A2,2,0,0,0,19,1ZM16,12H12v4H10V12H6V10h4V6h2v4h4Z"
    />
  </Svg>
);
