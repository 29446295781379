import * as React from 'react';
import theme from 'ui/theme/config';

interface ValidProps {
  fill?: string;
}

export const Valid = ({
  fill = theme.colors.stateColors.success.main,
}: ValidProps) => (
  <svg width={20} height={20} viewBox="0 0 20 20">
    <circle cx={10} cy={10} r={10} fill={fill} />
    <path
      stroke={theme.colors.grey[0]}
      strokeWidth={2}
      d="M4.707 9.722l4.572 4.571M7.864 14.293l8-8"
    />
  </svg>
);
