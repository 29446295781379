import { MouseEvent } from 'react';

import { Path, Svg } from './styles';

interface ArrowDownProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  strokeWidth?: string;
  color?: string;
  rotate?: string;
  variant?: 'primary' | 'secondary' | 'disabled';
  zIndex?: number | string;
}

export const ArrowDown = ({
  onClick,
  height = '2rem',
  width = '2rem',
  strokeWidth = '1',
  color,
  rotate,
  variant,
  zIndex,
}: ArrowDownProps) => (
  <Svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width={width}
    height={height}
    rotate={rotate}
    zIndex={zIndex}
  >
    <Path
      fill={color}
      variant={variant}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M11 15.87L4.67 9.54l1.41-1.41L11 13.04l4.92-4.91 1.41 1.41L11 15.87z"
    />
  </Svg>
);
