import { Path, Svg } from './styles';

interface PerformanceProps {
  height?: string;
  width?: string;
  strokeWidth?: string;
  color?: string;
  variant?: 'primary' | 'secondary';
}

export const Performance = ({
  height = '2rem',
  width = '3rem',
  strokeWidth = '1',
  color,
  variant,
}: PerformanceProps) => (
  <Svg
    data-testid="performance-id"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31 25"
    width={width}
    height={height}
  >
    <Path
      d="M30 0H4.1C3.6 0 3.3 0.3 3.3 0.8V4.9H1C0.4 4.9 0 5.4 0 5.9V23.3C0 23.9 0.5 24.3 1 24.3H24.9C25.5 24.3 25.9 23.8 25.9 23.3V21H30C30.4 21 30.8 20.7 30.8 20.2V0.8C30.7 0.3 30.4 0 30 0ZM7.3 20H4.1C3.7 20 3.3 19.7 3.3 19.2C3.3 18.7 3.6 18.4 4.1 18.4H7.3C7.7 18.4 8.1 18.7 8.1 19.2C8.1 19.7 7.7 20 7.3 20ZM7.3 15.5H4.1C3.7 15.5 3.3 15.2 3.3 14.7C3.3 14.2 3.6 14 4.1 14H7.3C7.7 14 8 14.4 8 14.8C8 15.2 7.7 15.5 7.3 15.5ZM7.3 11H4.1C3.7 11 3.3 10.7 3.3 10.2C3.3 9.7 3.6 9.4 4.1 9.4H7.3C7.7 9.5 8 9.8 8 10.3C8 10.8 7.7 11 7.3 11ZM14.6 20H11.4C11 20 10.6 19.7 10.6 19.2C10.6 18.7 10.9 18.4 11.4 18.4H14.6C15 18.4 15.4 18.7 15.4 19.2C15.4 19.7 15 20 14.6 20ZM14.6 15.5H11.4C11 15.5 10.6 15.2 10.6 14.7C10.6 14.2 10.9 13.9 11.4 13.9H14.6C15 13.9 15.4 14.2 15.4 14.7C15.4 15.2 15 15.5 14.6 15.5ZM14.6 11H11.4C11 11 10.6 10.7 10.6 10.2C10.6 9.7 10.9 9.4 11.4 9.4H14.6C15 9.4 15.4 9.7 15.4 10.2C15.4 10.7 15 11 14.6 11ZM21.9 20H18.7C18.3 20 17.9 19.7 17.9 19.2C17.9 18.7 18.2 18.4 18.7 18.4H21.9C22.3 18.4 22.7 18.7 22.7 19.2C22.7 19.7 22.3 20 21.9 20ZM21.9 15.5H18.7C18.3 15.5 17.9 15.2 17.9 14.7C17.9 14.2 18.2 13.9 18.7 13.9H21.9C22.3 13.9 22.7 14.2 22.7 14.7C22.7 15.2 22.3 15.5 21.9 15.5ZM21.9 11H18.7C18.3 11 17.9 10.7 17.9 10.2C17.9 9.7 18.2 9.4 18.7 9.4H21.9C22.3 9.4 22.7 9.7 22.7 10.2C22.7 10.7 22.3 11 21.9 11ZM29.2 19.5H25.9V5.8C25.9 5.2 25.4 4.8 24.9 4.8H4.8V1.5H29.2V19.5Z"
      fill={color}
      variant={variant}
      strokeWidth={strokeWidth}
    />
  </Svg>
);
