import theme from 'ui/theme/config';
interface SuccessIconProps {
  height?: string;
  width?: string;
}

export const SuccessIcon = ({
  height = '9.4rem',
  width = '9.4rem',
}: SuccessIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
    width={width}
    height={height}
  >
    <circle
      cx="75"
      cy="75"
      r="73.5"
      fill={theme.colors.grey[0]}
      stroke={theme.colors.primary.main}
      strokeWidth="3"
    />
    <line
      x1="44.1213"
      y1="76.7773"
      x2="64.2222"
      y2="96.8782"
      stroke={theme.colors.primary.main}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="108.667"
      y1="53.1213"
      x2="64.5656"
      y2="97.2222"
      stroke={theme.colors.primary.main}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
