import {
  DatePickerType,
  MonitoringGetDatePickerMutation,
  useMonitoringGetDatePickerMutation,
} from 'interfaces/graphql.types';
import { useAuth } from 'modules/auth/providers/AuthProvider';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { OptionType } from 'renderer';

const enabledRoutes = [
  '/dashboard',
  '/offering/[id]/investments',
  '/documents',
];

const useInvDatePicker = () => {
  const {
    state: { loggedIn },
  } = useAuth();
  const router = useRouter();

  const [datePicker, setDatePicker] = useState<DatePickerType>();

  const [datePickerData, setDatePickerData] = useState<
    MonitoringGetDatePickerMutation | null | undefined
  >();

  const [getDatePicker, { loading: loadingDatePicker = true }] =
    useMonitoringGetDatePickerMutation();
  const isRoute = enabledRoutes.includes(router.pathname);
  // To avoid calling the useMonitoringGetDatePickerMutation on every page, we check the current route and only call it on the necessary pages. This helps optimize performance and prevent unnecessary network requests.
  useEffect(() => {
    if (loggedIn && isRoute) {
      getDatePicker()
        .then((result) => {
          setDatePickerData(result.data);
          const newDatePicked =
            result.data?.monitoringGetDatePicker.datePicker?.[0];
          if (newDatePicked) setDatePicker(newDatePicked);
        })
        .catch(console.error);
    }
  }, [loggedIn, isRoute]);

  const onSelectChangeDatePicker = (datePickerOption?: OptionType | null) => {
    if (datePickerOption)
      setDatePicker({
        __typename: 'DatePickerType',
        name: datePickerOption.key,
        date: datePickerOption.value || '',
      });
  };

  return {
    loadingDatePicker,
    datePicker,
    setDatePicker,
    datePickerData,
    setDatePickerData,
    onSelectChangeDatePicker,
  };
};

export default useInvDatePicker;
