function handleIdleUserTimeout(
  loggedIn: boolean,
  logout: () => Promise<void>,
  timeoutLength: number
) {
  let time: NodeJS.Timeout | undefined;
  function resetTimer() {
    // clear old timer if it exists
    if (typeof time == 'number') clearTimeout(time);
    // set new timer
    time = setTimeout(logout, timeoutLength * 1000);
  }
  // cleanup events and timer
  function cleanup() {
    if (document.onmousemove?.name == resetTimer.name)
      document.onmousemove = null;
    if (document.onkeypress?.name == resetTimer.name)
      document.onkeypress = null;
    if (document.onmouseup?.name == resetTimer.name) document.onmouseup = null;
    if (typeof time == 'number') clearTimeout(time);
  }

  if (loggedIn) {
    resetTimer();
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    document.onmouseup = resetTimer;
  } else {
    cleanup();
  }

  return cleanup;
}

export default handleIdleUserTimeout;
