import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface SignInProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  as?: ElementType;
}

export const SignIn = ({
  onClick,
  height = '1.5rem',
  width = '1.5rem',
  as,
}: SignInProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.86 24"
    width={width}
    height={height}
    onClick={onClick}
    as={as}
  >
    <path d="M7.86,2h14V22h-14a1,1,0,1,0,0,2h15a1,1,0,0,0,1-1V1a1,1,0,0,0-1-1h-15a1,1,0,1,0,0,2Z" />
    <path d="M17.57,11.29,12.21,5.93a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L14.45,11H1a1,1,0,0,0,0,2H14.45l-3.66,3.66a1,1,0,0,0,0,1.41,1,1,0,0,0,1.42,0l5.36-5.36a1,1,0,0,0,0-1.42Z" />
  </Svg>
);
