import { ElementType } from 'react';
import theme from 'ui/theme/config';

import { Svg } from './styles';

interface LoaderIconProps {
  toColor?: string;
  fromColor?: string;
  height?: string;
  width?: string;
  as?: ElementType;
  dPath?: string;
}

export const LoaderIcon = ({
  height = '45',
  width = '45',
  toColor = theme.colors.grey[0],
  dPath = 'M73 50c0-12.7-10.3-23-23-23S27 37.3 27 50m3.9 0c0-10.5 8.5-19.1 19.1-19.1S69.1 39.5 69.1 50',
  as,
}: LoaderIconProps) => (
  <Svg
    as={as}
    width={width}
    height={height}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    display="block"
    data-testid="Loader-id"
  >
    <path d={dPath} fill={toColor}>
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        dur="0.7s"
        from="0 50 50"
        repeatCount="indefinite"
        to="360 50 50"
        type="rotate"
      />
    </path>
  </Svg>
);
