import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface OfferingsIconProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  as?: ElementType;
}

export const OfferingsIcon = ({
  onClick,
  height = '1.625rem',
  width = '1.625rem',
  as,
}: OfferingsIconProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26.28 26.27"
    width={width}
    height={height}
    onClick={onClick}
    as={as}
  >
    <path d="M24.17 0h-9.28a2.38 2.38 0 00-1.69.7L.7 13.2a2.4 2.4 0 000 3.38l9 9a2.41 2.41 0 003.38 0l12.5-12.51a2.38 2.38 0 00.7-1.69V2.11A2.11 2.11 0 0024.17 0zm.61 11.38a.87.87 0 01-.27.63L12 24.52a.91.91 0 01-1.26 0l-9-9a.89.89 0 010-1.26l12.5-12.5a.94.94 0 01.63-.26h9.28a.61.61 0 01.61.61z" />
    <path d="M19.16 4.29a2 2 0 102.83 0 2 2 0 00-2.83 0z" />
  </Svg>
);
