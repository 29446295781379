export const MapPin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 26 41"
  >
    <path
      fill="#21B4EF"
      d="M13 .5C6.1.5.5 5.84.5 12.44c0 2.76 2.21 8.16 4.76 13.48l.37.76.56 1.14.37.75C9.75 34.91 13 40.5 13 40.5s3.25-5.59 6.44-11.93l.37-.75.56-1.14.37-.76c2.55-5.32 4.76-10.72 4.76-13.48C25.5 5.84 19.9.5 13 .5zm0 17a5 5 0 115-5 5 5 0 01-5 5z"
      fillRule="evenodd"
    />
  </svg>
);
