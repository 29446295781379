import * as React from 'react';

export const DragArea = () => (
  <svg width={11} height={20} fill="none">
    <circle cx={2} cy={18} r={2} transform="rotate(-90 2 18)" fill="#DDD" />
    <circle cx={9} cy={18} r={2} transform="rotate(-90 9 18)" fill="#DDD" />
    <circle cx={2} cy={10} r={2} transform="rotate(-90 2 10)" fill="#DDD" />
    <circle cx={9} cy={10} r={2} transform="rotate(-90 9 10)" fill="#DDD" />
    <circle cx={2} cy={2} r={2} transform="rotate(-90 2 2)" fill="#DDD" />
    <circle cx={9} cy={2} r={2} transform="rotate(-90 9 2)" fill="#DDD" />
  </svg>
);
