import styled from 'styled-components';

interface SvgProps {
  height: string;
  width: string;
  inactive?: boolean;
  noHover?: boolean;
}

export const Svg = styled.svg<SvgProps>`
  border-radius: 2px;
  height: ${({ height, theme }) => `calc(${height} + ${theme.spacing[2]})`};
  width: ${({ width, theme }) => `calc(${width} + ${theme.spacing[2]})`};
  padding: ${({ theme }) => theme.spacing[1]};
  border-radius: 4px;

  ${({
    theme: {
      colors: { grey },
    },
    inactive,
  }) =>
    inactive &&
    `
            path {
                fill: ${grey[200]};
            }

    `}

  ${({ inactive, noHover, theme }) =>
    !inactive &&
    !noHover &&
    `
		&:hover {
			background-color: ${theme.colors.grey[200]} !important;
		}
	`};
`;
