import {
  DatePickerType,
  MonitoringGetDatePickerMutation,
} from 'interfaces/graphql.types';
import useInvDatePickerUtil from 'modules/dashboard/utils/useInvDatePicker';
import { createContext, useContext } from 'react';
import { OptionType } from 'renderer';

export const InvDatePickerContext = createContext<{
  datePicker: DatePickerType | undefined;
  datePickerData: MonitoringGetDatePickerMutation | null | undefined;
  loadingDatePicker: boolean;
  onSelectChangeDatePicker: (
    datePickerOption?: OptionType | null | undefined
  ) => void;
}>({
  datePicker: undefined,
  datePickerData: undefined,
  loadingDatePicker: false,
  onSelectChangeDatePicker: () => null,
});

const InvDatePickerProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    datePicker,
    datePickerData,
    loadingDatePicker,
    onSelectChangeDatePicker,
  } = useInvDatePickerUtil();

  return (
    <InvDatePickerContext.Provider
      value={{
        datePicker,
        datePickerData,
        loadingDatePicker,
        onSelectChangeDatePicker,
      }}
    >
      {children}
    </InvDatePickerContext.Provider>
  );
};

export const useInvDatePicker = () => useContext(InvDatePickerContext);

export default InvDatePickerProvider;
