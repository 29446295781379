import { CLIENT_HOMEPAGE } from 'config';
import { useClient } from 'modules/settings/providers/ClientProvider';
import React, { ReactNode, useEffect } from 'react';

import { getAccessRoute, verifyPath } from '../libs/routes';
import { NextShieldProps } from '../types/props';

export function NextShield<
  PrivateRoutesList extends string[],
  PublicRoutesList extends string[]
>({
  isAuth,
  isLoading,
  router: { pathname, replace, query },
  loginRoute,
  accessRoute,
  privateRoutes,
  publicRoutes,
  hybridRoutes,
  LoadingComponent,
  RBAC,
  userRole,
  children,
}: NextShieldProps<PrivateRoutesList, PublicRoutesList> & {
  children: ReactNode;
}) {
  const { client } = useClient();
  const pathIsPrivate = verifyPath(privateRoutes, pathname, client);
  const pathIsPublic = verifyPath(publicRoutes, pathname, client);
  const pathIsHybrid = verifyPath(hybridRoutes, pathname, client);
  const pathIsAuthorized =
    RBAC &&
    userRole &&
    verifyPath(RBAC[userRole].grantedRoutes, pathname, client);
  const access = getAccessRoute(RBAC, userRole, accessRoute);
  // console.log(client)
  // console.log(pathname)
  // console.log(pathIsPrivate)
  // console.log(pathIsPublic)
  // console.log(pathIsHybrid)
  // console.log(pathIsAuthorized)
  useEffect(() => {
    if (
      client !== 'relevant' &&
      client !== 'origdevdata' &&
      !isAuth &&
      !isLoading &&
      pathIsPrivate
    ) {
      replace(loginRoute, { query: { to: query.to } });
    }
    if (
      client !== 'relevant' &&
      client !== 'origdevdata' &&
      isAuth &&
      !isLoading &&
      pathIsPublic
    ) {
      replace(access);
    }
    if (
      client !== 'relevant' &&
      client !== 'origdevdata' &&
      isAuth &&
      userRole &&
      !isLoading &&
      !pathIsHybrid &&
      !pathIsAuthorized
    ) {
      replace(access);
    }

    if (
      client !== 'relevant' &&
      client !== 'origdevdata' &&
      !pathIsPrivate &&
      !pathIsPublic &&
      !pathIsHybrid
    ) {
      replace(CLIENT_HOMEPAGE);
    }
    if (!isAuth && pathIsPrivate) {
      replace(loginRoute, { query: { to: query.to } });
    }
  }, [
    replace,
    userRole,
    access,
    isAuth,
    isLoading,
    loginRoute,
    pathIsPrivate,
    pathIsPublic,
    pathIsHybrid,
    pathIsAuthorized,
  ]);
  /**
   * ! remove hooks inside conditions
   */
  if (client !== 'relevant' && client !== 'origdevdata') {
    if (isLoading) {
      return <>{LoadingComponent}</>;
    } else if (!isAuth && pathIsPrivate) {
      return <>{LoadingComponent}</>;
    } else if (
      userRole &&
      !pathIsAuthorized &&
      !pathIsHybrid &&
      !pathIsPublic
    ) {
      return <>{LoadingComponent}</>;
    }
    return <>{children}</>;
  } else {
    return <>{children}</>;
  }
}
