import styled from 'styled-components';

interface SvgProps {
  shadow?: boolean;
  height: string;
  width: string;
  rotate?: string;
}

interface PathProps {
  variant?: 'primary' | 'secondary';
  color?: string;
}

export const Svg = styled.svg<SvgProps>`
  padding: 0.3rem;
  z-index: 1;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  ${({ rotate }) => rotate && `transform: rotate(${rotate}deg)`}
`;

export const Path = styled.path<PathProps>`
  fill: ${({
    theme: {
      colors: { primary, grey },
    },
    variant,
    color,
  }) => (color ? color : variant === 'primary' ? primary.main : grey[300])};
`;
