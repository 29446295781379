import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface MailProps {
  onClick?: (e: MouseEvent) => void;
  color?: string;
  height?: string;
  width?: string;
  strokeWidth?: string;
  inactive?: boolean;
  as?: ElementType;
}

export const Mail = ({
  onClick,
  height = '1.25rem',
  width = '1.25rem',
  strokeWidth = '1',
  as,
}: MailProps) => (
  <Svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width={width}
    height={height}
    as={as}
  >
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M20.41 4.29H1.59a.33.33 0 00-.2.57l9.45 7.52a.29.29 0 00.39 0l9.38-7.52a.33.33 0 00-.2-.57"
    />
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M11.56 14.84a.82.82 0 01-1.12 0L1.64 8S1 7.48 1 8.2v8.7a.78.78 0 00.76.81h18.45a.78.78 0 00.79-.81V8.2c0-.72-.65-.2-.65-.2z"
    />
  </Svg>
);
