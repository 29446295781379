import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  fonts: {
    primary: 'Produkt',
    secondary: 'Gotham',
    tertiary: 'Gotham Office',
    weight: {
      hairline: '100',
      thin: '200',
      light: '300',
      normal: '400',
      medium: '500',
      semiBold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
    size: {
      xxs: '0.09375rem', // 1.5px
      xs: '0.7rem', // 11.2px
      xs1: '0.75rem', // 12px
      sm: '0.8125rem', // 13px
      sm1: '0.875rem', // 14px
      sm2: '0.938rem', // 15.008px
      sm3: '0.95rem', // 15.2px
      base: '1rem', // 16px
      lg: '1.125rem', // 18px
      xl: '1.25rem', // 20px
      xl2: '1.4rem', // 22.4px
      xl3: '1.5rem', // 24px
      xl4: '1.875rem', // 30px
      xl5: '2.25rem', // 36px
      xl6: '2.5rem', // 40px
      xl7: '3rem', // 48px
      xl8: '3.125rem', // 50px
      xl9: '3.175rem', // 50.8px
      xl10: '4rem', // 64px#
      xl11: '4.2rem', // 67.2px
    },
  },
  colors: {
    black: {
      200: '#1F1F25',
      300: '#333333',
      400: '#222222',
      500: '#121212',
      600: '#383840',
      900: '#000',
    },
    grey: {
      0: '#ffffff',
      100: '#f9f9f9',
      200: '#f3f3f5',
      300: '#dadada',
      400: '#aaaaaa',
      500: '#666666',
      600: '#333333',
      700: '#222222',
      800: '#000000',
    },
    icons: {
      blue: '#21b4ef',
      green: '#67c272',
      purple: '#623a9d',
      red: '#c21f30',
      yellow: '#E4AD3F  ',
    },
    vividTangerine: '#ff7f7f',
    lightCoral: '#F08585',
    darkGray: '#777777',
    darkGrayBlue: '#A3A5A9',
    primary: {
      200: '#C4EBFB',
      450: '#14BCFF',
      500: '#21B4EF',
      600: '#18A2DA',
      lighter: '#c4ebfb',
      light: '#14bcff',
      main: '#21B4EF',
      dark: '#18a2da',
    },
    secondary: {
      light: '#39c7ff',
      main: '#21B4EF',
      dark: '#18a2da',
    },
    stateColors: {
      danger: {
        lighter: '#ffe2e6',
        light: '#f3445f',
        main: '#e33751',
        dark: '#c83047',
        darker: '#c21f30',
        darkest: '#C21F30',
      },
      success: {
        lighter: '#caefcf',
        light: '#70d47c',
        main: '#67c272',
        dark: '#5dae67',
      },
      info: {
        lighter: '#c4ebfb',
        light: '#7ED3F6',
        main: '#14bcff',
        dark: '#21b4ef',
      },
      review: '#623A9D',
      lightDeactivated: '#F0F1F0',
      deactivated: '#979797',
    },
  },
  spaces: {
    p4: '4px',
    p8: '8px',
    p12: '12px',
    p16: '16px',
    p20: '20px',
    p24: '24px',
    p32: '32px',
    p48: '48px',
    p64: '64px',
  },
  spacing: {
    1: '0.25rem', // 4px
    1.5: '0.375rem', // 6px
    2: '0.5rem', // 8px
    2.5: '0.625rem', // 10px
    3: '0.75rem', // 12px
    3.5: '0.875rem', // 14px
    4: '1rem', // 16px
    5: '1.25rem', // 20px
    5.5: '1.375rem', // 22px
    6: '1.5rem', // 24px
    7: '1.75rem', // 28px
    8: '2rem', // 32px
    9: '2.25rem', // 36px
    10: '2.5rem', // 40px
    11: '2.75rem', // 44px
    12: '3rem', // 48px
    14: '3.5rem', // 56px
    15: '3.75rem', // 60px
    16: '4rem', // 64px
    20: '5rem', // 80px
    24: '6rem', // 96px
    28: '7rem', // 112px
    32: '8rem', // 128px
    36: '9rem', // 144px
    40: '10rem', // 160px
    44: '11rem', //  176px
    48: '12rem', // 192px
    52: '13rem', // 208px
    56: '14rem', // 224px
  },
  breakpoints: {
    xs: '0px',
    sm: '600px',
    md: '960px',
    lg: '1280px',
    adminLg: '1450px',
    xl: '1920px',
  },
};

export default theme;
