import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface PhoneProps {
  onClick?: (e: MouseEvent) => void;
  color?: string;
  height?: string;
  width?: string;
  strokeWidth?: string;
  inactive?: boolean;
  as?: ElementType;
}

export const Phone = ({
  onClick,
  height = '1.25rem',
  width = '1.25rem',
  as,
}: PhoneProps) => (
  <Svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width={width}
    height={height}
    as={as}
  >
    <defs>
      <path d="M14.82 23.44L-1.44 7.17 4.92.81l16.27 16.26-6.37 6.37z" />
    </defs>
    <path d="M2 7.82C2.37 9.4 3.37 12 6.69 15.31s5.91 4.32 7.49 4.69a5.58 5.58 0 005.53-1.49c.09-.09 1.12-2.33.05-2.84l-3.86-2a.6.6 0 00-.74.16l-1.05 1.33a1.1 1.1 0 01-1.27.33A13.8 13.8 0 019 13a13.54 13.54 0 01-2.52-3.84 1.1 1.1 0 01.33-1.27l1.33-1a.6.6 0 00.16-.79l-2-3.86c-.51-1.07-2.75 0-2.84 0A5.58 5.58 0 002 7.82" />
  </Svg>
);
