import { Maybe } from 'interfaces/graphql.types';
import { createContext, useContext, useState } from 'react';

type InvestorInfoType =
  | {
      name?: Maybe<string> | undefined;
      id?: string | undefined | string[];
      topInvestor?: boolean;
    }
  | undefined;

export const NavBarContext = createContext<{
  leftOpen: boolean;
  investorInfo?: InvestorInfoType;
  showLeftNavBar: () => void;
  closeLeftNavBar: VoidFunction;
  saveInvestorInfo?: (arg: InvestorInfoType) => void;
}>({
  leftOpen: false,
  investorInfo: {},
  showLeftNavBar: () => {},
  closeLeftNavBar: () => {},
  saveInvestorInfo: () => {},
});

const NavBarProvider = ({ children }: { children: React.ReactNode }) => {
  const [leftOpen, setLeftOpen] = useState<boolean>(false);
  const [investorInfo, setInvestorInfo] = useState<InvestorInfoType>(undefined);

  function showLeftNavBar() {
    setLeftOpen(true);
  }

  function closeLeftNavBar() {
    setLeftOpen(false);
  }

  function saveInvestorInfo(investorInfo: InvestorInfoType) {
    setInvestorInfo(investorInfo);
  }

  return (
    <NavBarContext.Provider
      value={{
        leftOpen,
        investorInfo,
        showLeftNavBar,
        closeLeftNavBar,
        saveInvestorInfo,
      }}
    >
      {children}
    </NavBarContext.Provider>
  );
};

export const useNavBar = () => useContext(NavBarContext);

export default NavBarProvider;
