import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface LogoutProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  as?: ElementType;
}

export const Logout = ({
  onClick,
  height = '1.5rem',
  width = '1.5rem',
  as,
}: LogoutProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.86 24"
    width={width}
    height={height}
    onClick={onClick}
    as={as}
  >
    <path d="M16 22H2V2h14a1 1 0 000-2H1a1 1 0 00-1 1v22a1 1 0 001 1h15a1 1 0 000-2z" />
    <path d="M23.57 11.29l-6.36-6.36a1 1 0 00-1.42 0 1 1 0 000 1.41L20.45 11H7a1 1 0 000 2h13.45l-4.66 4.66a1 1 0 000 1.41 1 1 0 001.42 0l6.36-6.36a1 1 0 000-1.42z" />
  </Svg>
);
