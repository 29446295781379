import styled from 'styled-components';
import { colorWithOpacity } from 'utils/helpers/colorWithOpacity';

interface SvgProps {
  height: number;
  width: number;
}

export const SvgWrapper = styled.div<SvgProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width + 8}px;
  height: ${({ height }) => height + 8}px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease;

  &:hover {
    background-color: ${({ theme }) =>
      colorWithOpacity(theme.colors.grey[500], 0.1)};
  }

  &:active {
    background-color: ${({ theme }) =>
      colorWithOpacity(theme.colors.grey[600], 0.2)};
  }
`;

export const Svg = styled.svg<SvgProps>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  transition: all 0.1s ease;

  &:hover {
    height: ${({ height }) => height + 4}px;
    width: ${({ width }) => width + 4}px;
  }

  &:active {
    height: ${({ height }) => height}px;
    width: ${({ width }) => width}px;
    color: ${({ theme }) => theme.colors.grey[600]};
  }
`;
