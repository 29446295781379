import React, { MouseEvent } from 'react';

import { Svg } from './styles';

interface PropertyDetailIconProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  strokeWidth?: string;
  as?: React.ElementType;
}

export const PropertyDetailIcon = ({
  onClick,
  height = '1.875rem',
  width = '1.875rem',
  strokeWidth = '1',
  as,
}: PropertyDetailIconProps) => (
  <Svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 28"
    width={width}
    height={height}
    as={as}
  >
    <path
      strokeWidth={strokeWidth}
      d="M28.8 24.2l-5.6-5.5c1.1-1.4 1.8-3.1 1.8-4.9C25 9.5 21.5 6 17.2 6H17V1.8c0-.5-.3-.8-.8-.8h-14c-.4 0-.7.3-.7.8v23.9c0 .4.3.7.8.8h14c.4 0 .7-.3.8-.8v-4.1h.2c1.8 0 3.5-.6 4.9-1.8l5.6 5.5c.3.3.8.3 1.1 0 .2-.3.2-.8-.1-1.1zm-13.3.7H3V2.5h12.5v3.7c-.9.2-1.7.6-2.5 1.1V5.8c0-.5-.3-.8-.8-.8-.4 0-.8.3-.8.8v2.8c-2.7 2.9-2.7 7.4 0 10.3v2.3c0 .4.3.8.8.8.4 0 .8-.3.8-.8v-1c.8.5 1.6.8 2.5 1v3.7zm1.7-4.9c-3.5 0-6.2-2.8-6.2-6.2s2.8-6.2 6.2-6.2 6.2 2.8 6.2 6.2-2.7 6.2-6.2 6.2zM7 5.8v2.5c0 .4-.3.7-.8.7s-.7-.3-.7-.7V5.8c0-.5.3-.8.7-.8.5 0 .8.3.8.8zm0 6.5v2.5c0 .4-.3.8-.8.8s-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8s.8.3.8.8zm0 6.5v2.5c0 .4-.3.7-.8.7s-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8.5.1.8.4.8.9z"
    />
  </Svg>
);
