import styled from 'styled-components';

interface SvgProps {
  height: string;
  width: string;
  inactive?: boolean;
}

export const Svg = styled.svg<SvgProps>`
  border-radius: 2px;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  cursor: pointer;

  ${({
    theme: {
      colors: { grey, primary },
    },
    inactive,
  }) =>
    inactive
      ? `
            path {
                fill: ${grey[200]};
            }
    `
      : `
            path {
                fill: ${primary.main};
            }
    &:hover {
        path {
            fill: ${primary.light};
        }
    }
    &:active {
        fill: ${primary.dark};
        path {
            fill: ${primary.dark};
        }
    }
    `}
`;
