import styled from 'styled-components';

interface SvgProps {
  height: string;
  width: string;
  fillCircle?: string;
  fillPlus?: string;
}

export const Svg = styled.svg<SvgProps>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  cursor: pointer;

  path {
    fill: ${({ fillPlus, theme }) => fillPlus || theme.colors.primary.main};

    &:first-child {
      ${({ fillPlus }) => (fillPlus ? `fill: ${fillPlus};` : '')};
    }

    &:last-child {
      ${({ fillCircle }) => (fillCircle ? `fill: ${fillCircle};` : '')}
    }
  }
`;
