import React, { useState } from 'react';
import { Info } from 'ui/Icons';

import { InfoContainer, Triangle, Wrapper } from './styles';

export const InfoLabel = () => {
  const [displayInfo, setDisplayInfo] = useState(false);
  return (
    <Wrapper>
      <InfoContainer className="info" displayInfo={displayInfo}>
        Click cell value to view sources
        <Triangle />
      </InfoContainer>
      <Info
        onClick={() => {
          setDisplayInfo(!displayInfo);
        }}
      />
    </Wrapper>
  );
};
