import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface DocProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  badge?: boolean;
  as?: ElementType;
  optional?: boolean;
}

export const Doc = ({
  onClick,
  height = '1.8rem',
  width = '1.8rem',
  badge = false,
  as,
  optional,
}: DocProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 30"
    width={width}
    height={height}
    onClick={onClick}
    as={as}
  >
    <path fill="#f3f4f8" d="M2 5h17v23H2z" />
    <path fill="#989eb5" d="M20 29H1V4h19zM2 28h17V5H2z" />
    {badge ? (
      <>
        <path
          fill="#989eb5"
          d="M4.5 10.5h6v1h-6zM4.5 23.5h12v1h-12zM4.5 19.5h12v1h-12zM4.5 15.5h12v1h-12z"
        />
        <circle
          cx="19"
          cy="5"
          r="4.5"
          fill={`${optional ? '#21B4EF' : '#f08585'}`}
        />
      </>
    ) : (
      <path
        fill="#989eb5"
        d="M4.5 10.5h6v1h-6zM4.5 23.5h12v1h-12zM4.5 19.5h12v1h-12zM4.5 15.5h12v1h
            12z"
      />
    )}
  </Svg>
);
