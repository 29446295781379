import { Path, Svg } from './styles';

interface UsersProps {
  height?: string;
  width?: string;
  strokeWidth?: string;
  color?: string;
  variant?: 'primary' | 'secondary';
}

export const Users = ({
  height = '3rem',
  width = '3rem',
  strokeWidth = '1',
  color,
  variant,
}: UsersProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={width}
    height={height}
  >
    <Path
      d="M16.75 12.0001C16.75 9.52514 14.725 7.50014 12.25 7.50014C9.77499 7.50014 7.74999 9.52514 7.74999 12.0001C7.74999 14.4751 9.77499 16.5001 12.25 16.5001C14.725 16.5001 16.75 14.4751 16.75 12.0001ZM12.25 13.5001C11.425 13.5001 10.75 12.8251 10.75 12.0001C10.75 11.1751 11.425 10.5001 12.25 10.5001C13.075 10.5001 13.75 11.1751 13.75 12.0001C13.75 12.8251 13.075 13.5001 12.25 13.5001ZM22 16.5001C23.665 16.5001 25 15.1651 25 13.5001C25 11.8351 23.665 10.5001 22 10.5001C20.335 10.5001 18.985 11.8351 19 13.5001C19 15.1651 20.335 16.5001 22 16.5001ZM15.985 0.0151367C7.70499 0.0151367 0.984985 6.73514 0.984985 15.0151C0.984985 23.2951 7.70499 30.0151 15.985 30.0151C24.265 30.0151 30.985 23.2951 30.985 15.0151C30.985 6.73514 24.265 0.0151367 15.985 0.0151367ZM6.75999 22.6801C7.77999 21.8701 10.165 21.0151 12.25 21.0151C12.355 21.0151 12.475 21.0301 12.595 21.0301C12.955 20.0701 13.6 19.0951 14.545 18.2401C13.705 18.0901 12.91 18.0001 12.25 18.0001C10.3 18.0001 7.16499 18.6751 5.15499 20.1451C4.40499 18.5851 3.98499 16.8451 3.98499 15.0001C3.98499 8.38514 9.36999 3.00014 15.985 3.00014C22.6 3.00014 27.985 8.38514 27.985 15.0001C27.985 16.8001 27.58 18.5101 26.86 20.0551C25.36 19.1701 23.32 18.7501 22 18.7501C19.72 18.7501 15.25 19.9651 15.25 22.8001V26.9701C11.845 26.7751 8.81499 25.1551 6.75999 22.6801Z"
      color={color}
      variant={variant}
      strokeWidth={strokeWidth}
    />
  </Svg>
);
