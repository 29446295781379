import { CLIENT_HOMEPAGE } from 'config';

import { RoleAccess } from '../types/props';
export function verifyPath(
  routes: string[] | undefined,
  uri: string,
  client: string
) {
  if (client !== 'relevant' && client !== 'origdevdata') {
    return routes?.some((route) => route === uri);
  }
  return routes?.some((route) => uri.includes(route));
}

export function getAccessRoute(
  RBAC: RoleAccess<string[]> | undefined,
  userRole: string | undefined,
  accessRoute: string | undefined
) {
  if (typeof accessRoute !== 'undefined') return accessRoute;

  if (RBAC && userRole) return RBAC[userRole].accessRoute;

  return CLIENT_HOMEPAGE;
}
