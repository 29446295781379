import { MouseEvent } from 'react';

import { Svg } from './styles';

interface InvestmentIconProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  color?: string;
  isActive?: boolean;
}

export const InvestmentIcon = ({
  onClick,
  height = '36',
  width = '36',
  color = '#AAAAAA',
  isActive,
}: InvestmentIconProps) =>
  isActive ? (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M6 28.5H12V16.5H6V28.5ZM15 28.5H21V7.5H15V28.5ZM24 28.5H30V19.5H24V28.5ZM3 28.5V16.5C3 15.675 3.29375 14.9688 3.88125 14.3813C4.46875 13.7937 5.175 13.5 6 13.5H12V7.5C12 6.675 12.2937 5.96875 12.8813 5.38125C13.4688 4.79375 14.175 4.5 15 4.5H21C21.825 4.5 22.5312 4.79375 23.1188 5.38125C23.7063 5.96875 24 6.675 24 7.5V16.5H30C30.825 16.5 31.5312 16.7938 32.1188 17.3813C32.7063 17.9688 33 18.675 33 19.5V28.5C33 29.325 32.7063 30.0312 32.1188 30.6188C31.5312 31.2063 30.825 31.5 30 31.5H6C5.175 31.5 4.46875 31.2063 3.88125 30.6188C3.29375 30.0312 3 29.325 3 28.5Z"
        fill="url(#paint0_linear_20166_743)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20166_743"
          x1="3"
          y1="4.5"
          x2="29.8508"
          y2="34.3343"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#20F8F8" />
          <stop offset="1" stop-color="#017AE9" />
        </linearGradient>
      </defs>
    </Svg>
  ) : (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M6 28.5H12V16.5H6V28.5ZM15 28.5H21V7.5H15V28.5ZM24 28.5H30V19.5H24V28.5ZM3 28.5V16.5C3 15.675 3.29375 14.9688 3.88125 14.3813C4.46875 13.7937 5.175 13.5 6 13.5H12V7.5C12 6.675 12.2937 5.96875 12.8813 5.38125C13.4688 4.79375 14.175 4.5 15 4.5H21C21.825 4.5 22.5312 4.79375 23.1188 5.38125C23.7063 5.96875 24 6.675 24 7.5V16.5H30C30.825 16.5 31.5312 16.7938 32.1188 17.3813C32.7063 17.9688 33 18.675 33 19.5V28.5C33 29.325 32.7063 30.0312 32.1188 30.6188C31.5312 31.2063 30.825 31.5 30 31.5H6C5.175 31.5 4.46875 31.2063 3.88125 30.6188C3.29375 30.0312 3 29.325 3 28.5Z"
        fill={color}
      />
    </Svg>
  );
