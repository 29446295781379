import { MouseEvent } from 'react';

import { Path, Svg } from './styles';

interface PropertiesProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  strokeWidth?: string;
  color?: string;
  variant?: 'primary' | 'secondary';
}

export const Properties = ({
  onClick,
  height = '3.75rem',
  width = '3rem',
  strokeWidth = '1',
  color,
  variant,
}: PropertiesProps) => (
  <Svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 26"
    width={width}
    height={height}
  >
    <Path
      d="M32.115 17.37L18.615 3.87C18.075 3.33 17.325 3 16.5 3H6C4.35 3 3 4.35 3 6V16.5C3 17.325 3.33 18.075 3.885 18.63L17.385 32.13C17.925 32.67 18.675 33 19.5 33C20.325 33 21.075 32.67 21.615 32.115L32.115 21.615C32.67 21.075 33 20.325 33 19.5C33 18.675 32.655 17.91 32.115 17.37ZM19.5 30.015L6 16.5V6H16.5V5.985L30 19.485L19.5 30.015Z"
      variant={variant}
      strokeWidth={strokeWidth}
      color={color}
    />
    <Path
      d="M9.75 12C10.9926 12 12 10.9926 12 9.75C12 8.50736 10.9926 7.5 9.75 7.5C8.50736 7.5 7.5 8.50736 7.5 9.75C7.5 10.9926 8.50736 12 9.75 12Z"
      variant={variant}
      strokeWidth={strokeWidth}
      color={color}
    />
  </Svg>
);
