import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface PersonIconProps {
  onClick?: (e: MouseEvent) => void;
  height?: string | number;
  width?: string | number;
  color?: string;
  stroke?: string;
  as?: ElementType;
}

export const PersonIcon = ({
  onClick,
  height = '21',
  width = '19',
  as,
}: PersonIconProps) => (
  <>
    <Svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      as={as}
      onClick={onClick}
      data-testid="personIcon"
    >
      <path
        d="M12.472 4.016c0 1.788-1.419 3.214-3.139 3.214-1.72 0-3.139-1.426-3.139-3.214 0-1.789 1.42-3.214 3.14-3.214 1.72 0 3.138 1.425 3.138 3.214zM17.841 16.843a14.12 14.12 0 01-8.508 2.845c-3.212 0-6.137-1.07-8.508-2.845.887-3.978 4.38-6.98 8.508-6.98 4.129 0 7.622 3.002 8.508 6.98z"
        fill="#fff"
        stroke="#424249"
        strokeWidth="1.5"
      />
    </Svg>
  </>
);
