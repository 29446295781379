import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface CheckedProps {
  onClick?: (e: MouseEvent) => void;
  color?: string;
  height?: string;
  width?: string;
  as?: ElementType;
}

export const Checked = ({
  onClick,
  height = '1.25rem',
  width = '1.25rem',
  as,
}: CheckedProps) => (
  <Svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width={width}
    height={height}
    as={as}
  >
    <path d="M11 .5A10.5 10.5 0 1021.5 11 10.51 10.51 0 0011 .5zm0 20a9.5 9.5 0 119.5-9.5 9.51 9.51 0 01-9.5 9.5z" />
    <path d="M9 13.71l-2.85-2.84-1.28 1.28L9 16.29l8.39-8.39-1.29-1.28L9 13.71z" />
  </Svg>
);
