import styled from 'styled-components';

export const Wrapper = styled.div`
  max-height: 20px;
  &:hover .info {
    display: block;
  }
`;

export const InfoContainer = styled.div<{ displayInfo?: boolean }>`
  text-align: center;
  display: ${({ displayInfo }) => (displayInfo ? 'block' : 'none')};
  position: absolute;
  margin-top: -4rem;
  margin-left: -3.9rem;
  background: black;
  color: white;
  padding: 5px;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  border-radius: 0.3rem;
  max-width: 9rem;
`;

export const Triangle = styled.div`
  display: inline;
  width: 0;
  height: 0;
  margin-left: -39%;
  margin-top: 1.48rem;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  top: 1;
  border-style: solid;
  border-width: 0px 0.875rem 1.081rem 0.875rem;
  position: absolute;
  border-color: transparent transparent black transparent;
`;
