import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { CLIENT } from 'config';
import { GraphQLError } from 'graphql';
import { delay } from 'utils/helpers/delay';

export type SentryLevelType = 'info' | 'log' | 'error' | 'fatal';

const convertSeverityToLevel = (severity: number) => {
  const levels = ['info', 'log', 'error', 'fatal'];

  if ([1, 2, 3, 4].includes(severity)) {
    return levels[severity - 1] as SentryLevelType;
  }
  return 'error';
};

export type ErrorType = {
  message?: string;
  errorMessage?: string;
  code?: number;
  severity?: number;
};

const isErrorType = (error: unknown): error is ErrorType =>
  Boolean(
    error &&
      typeof error == 'object' &&
      ('message' in error || 'errorMessage' in error)
  );

const isErrorListType = (errors: unknown): errors is ErrorType[] => {
  if (!Array.isArray(errors) || !errors.length) return false;
  return isErrorType(errors[0]);
};

export type FormattedErrorType = {
  error: unknown;
  code?: number;
  message?: string;
  level?: SentryLevelType;
};

export const formatError = (error: unknown): FormattedErrorType => {
  if (error instanceof ApolloError) {
    if (error.graphQLErrors[0]?.extensions?.code === 'INTERNAL_SERVER_ERROR') {
      return {
        error,
        message: 'Invalid User',
        code: 400,
      };
    }
    return {
      error,
      message: error.message,
      code: 0,
    };
  } else if (error instanceof GraphQLError) {
    return {
      error,
      message: error.message,
      code: 0,
    };
  } else if (isErrorListType(error)) {
    return {
      error: new Error(
        error[0].message?.replace('\n', ' ') ||
          error[0].errorMessage?.replace('\n', ' ')
      ),
      message: error[0].message || error[0].errorMessage,
      code: error[0]?.code || 0,
      level: error[0].severity
        ? convertSeverityToLevel(error[0].severity)
        : 'error',
    };
  } else if (isErrorType(error)) {
    return {
      error: new Error(
        error.message?.replace('\n', ' ') ||
          error.errorMessage?.replace('\n', ' ')
      ),
      message: error.message || error.errorMessage,
      code: error?.code || 0,
      level: error.severity ? convertSeverityToLevel(error.severity) : 'error',
    };
  } else {
    return {
      error,
      message: 'UNABLE TO FORMAT ERROR',
      code: 500,
    };
  }
};

export interface ISetSentryParams {
  userId?: string;
  errorCode?: number;
  errorMessage?: string;
  level?: SentryLevelType;
}

const setSentryError = (
  error: unknown,
  { userId, errorCode, errorMessage, level }: ISetSentryParams = {}
) => {
  const formattedError = formatError(error);

  const message = errorMessage || formattedError.message || 'unknown error';

  const extraData = {
    userId: userId || 'unknown',
    codeID:
      CLIENT === 'jvm' ? 'JVM' : CLIENT === 'solyco' ? 'Solyco' : 'Relevant',
    ProductID: 'frontend',
    errorCode: errorCode || formattedError?.code || 0,
    errorMessage: message.replace('\n', ' '),
  };

  Sentry.withScope(function (scope) {
    scope.setFingerprint(['{{ default }}', String(formattedError.message)]);
    Sentry.captureException(formattedError.error, {
      tags: extraData,
      extra: extraData,
      level: level || (formattedError.level as SentryLevelType) || 'error',
    });
  });
};

export const setSentryErrors = async (
  errors: unknown,
  params: ISetSentryParams = {}
) => {
  if (Array.isArray(errors)) {
    for (const error of errors) {
      setSentryError(error, params);
      await delay(1000);
    }
  } else {
    setSentryError(errors, params);
  }
};
