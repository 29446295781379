import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface SettingsProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  as?: ElementType;
}

export const Settings = ({
  onClick,
  height = '1.625rem',
  width = '1.625rem',
  as,
}: SettingsProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.5 25.5"
    width={width}
    height={height}
    onClick={onClick}
    as={as}
  >
    <path d="M24.75 10.11H22a9.79 9.79 0 00-.83-2l2-2a.75.75 0 000-1.06l-2.74-2.66a.77.77 0 00-1.06 0l-2 2a9.79 9.79 0 00-2-.83V.75a.75.75 0 00-.73-.75h-3.78a.75.75 0 00-.75.75v2.78a9.79 9.79 0 00-2 .83l-2-2a.75.75 0 00-.53-.22.77.77 0 00-.53.22L2.39 5.07a.75.75 0 000 1.06l2 2a9.79 9.79 0 00-.83 2H.75a.75.75 0 00-.75.75v3.78a.75.75 0 00.75.75h2.78a9.79 9.79 0 00.83 2l-2 2a.75.75 0 000 1.06l2.68 2.68a.77.77 0 001.06 0l2-2a9.79 9.79 0 002 .83v2.78a.75.75 0 00.75.75h3.78a.75.75 0 00.75-.75V22a9.79 9.79 0 002-.83l2 2a.75.75 0 00.53.22.77.77 0 00.53-.22l2.68-2.68a.75.75 0 000-1.06l-2-2a9.79 9.79 0 00.83-2h2.78a.75.75 0 00.75-.75v-3.82a.75.75 0 00-.73-.75zM24 13.89h-2.62a.76.76 0 00-.73.59 8 8 0 01-1.09 2.63.74.74 0 00.1.93l1.85 1.86-1.61 1.61-1.9-1.85a.74.74 0 00-.93-.1 7.89 7.89 0 01-2.63 1.09.76.76 0 00-.59.73V24h-2.24v-2.62a.76.76 0 00-.59-.73 7.89 7.89 0 01-2.63-1.09.74.74 0 00-.93.1L5.6 21.51 4 19.9 5.84 18a.74.74 0 00.1-.93 8 8 0 01-1.09-2.63.76.76 0 00-.73-.59H1.5v-2.24h2.62a.76.76 0 00.73-.61 8 8 0 011.09-2.61.74.74 0 00-.1-.93L4 5.6 5.6 4l1.86 1.84a.74.74 0 00.93.1A8 8 0 0111 4.85a.76.76 0 00.59-.73V1.5h2.28v2.62a.76.76 0 00.59.73 8 8 0 012.63 1.09.74.74 0 00.93-.1L19.9 4l1.61 1.6-1.85 1.86a.74.74 0 00-.1.93A8 8 0 0120.65 11a.76.76 0 00.73.59H24z" />
    <path d="M12.75 6.5A6.25 6.25 0 1019 12.75a6.25 6.25 0 00-6.25-6.25zm0 11a4.75 4.75 0 114.75-4.75 4.75 4.75 0 01-4.75 4.75z" />
  </Svg>
);
