import { ElementType } from 'react';

import { Svg } from './styles';

interface QMarkIconProps {
  height?: string | number;
  width?: string | number;
  as?: ElementType;
}

export const QMarkIcon = ({
  height = '5.375rem',
  width = '4.125rem',
  as,
}: QMarkIconProps) => (
  <Svg
    width={width}
    height={height}
    as={as}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 84"
  >
    <mask
      id="a"
      x="-.38"
      y="-1.15"
      width="66"
      height="86"
      maskUnits="userSpaceOnUse"
    >
      <path className="a" d="M-.38-1.15h56v76h-56z" />
      <path className="a" d="M9.62 8.85h56v76h-56z" />
    </mask>
    <path fill="#f3f3f5" fillRule="evenodd" d="M10 8.58h46V1H1v76h9V8.58z" />
    <path
      className="c"
      d="M4.5 78H0v-4.5h2V76h2.5v2zM2 68.19H0v-7.43h2zm0-12.74H0V48h2zm0-12.73H0v-7.44h2zM2 30H0v-7.45h2zm0-12.76H0V9.81h2zM2 4.5H0V0h4.5v2H2v2.5zM48 2h-6.37V0H48zM37.1 2h-6.34V0h6.34zM26.24 2H19.9V0h6.34zM15.37 2H9V0h6.34zM57 4.5h-2V2h-2.5V0H57v4.5zM66 84h-4.5v-2H64v-2.5h2V84zM57 84h-6.37v-2H57zm-10.9 0h-6.34v-2h6.34zm-10.86 0H28.9v-2h6.34zm-10.87 0H18v-2h6.34zM13.5 84H9v-4.5h2V82h2.5v2zM11 74.35H9v-7.22h2zM11 62H9v-7.24h2zm0-12.37H9v-7.24h2zm0-12.37H9V30h2zm0-12.37H9v-7.24h2zM11 12.5H9V8h4.5v2H11v2.5zM57 10h-6.37V8H57zm-10.9 0h-6.34V8h6.34zm-10.86 0H28.9V8h6.34zm-10.87 0H18V8h6.34zM66 12.5h-2V10h-2.5V8H66v4.5zM66 74.35h-2v-7.22h2zM66 62h-2v-7.24h2zm0-12.37h-2v-7.24h2zm0-12.37h-2V30h2zm0-12.37h-2v-7.24h2z"
    />
    <g className="e" mask="url(#a)">
      <g className="e">
        <path
          className="c"
          d="M35.65 45.81l.17-.17c4.42-.1 6.76-1.93 6.76-4.79v-.07c0-2.45-1.9-4.42-5-4.42-2.52 0-4.45 1.19-6.19 3.2l-1.69-1.67a9.8 9.8 0 018-3.94c4.59 0 7.61 2.85 7.61 6.73v.07c0 4.25-3.19 6.35-7.07 6.9l-.34 3.67h-1.74zm-.21 8.67h3.13v3.57h-3.13z"
        />
      </g>
    </g>
  </Svg>
);
