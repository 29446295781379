import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface DescendingProps {
  style?: Record<string, unknown>;
  onClick?: (e: MouseEvent) => void;
  color?: string;
  height?: string;
  width?: string;
  strokeWidth?: string;
  inactive?: boolean;
  as?: ElementType;
}

export const Descending = ({
  onClick,
  height = '31',
  width = '31',
  inactive = false,
  as,
}: DescendingProps) => (
  <Svg
    inactive={inactive}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31 31"
    width={width}
    height={height}
    as={as}
  >
    <mask
      id="descending0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="31"
      height="31"
    >
      <circle r="15.5" transform="matrix(1 0 0 -1 15.5 15.5)" fill="#C4C4C4" />
    </mask>
    <g mask="url(#descending0)">
      <rect
        width="40"
        height="40"
        transform="matrix(1 0 0 -1 -5 36)"
        fill="white"
      />
      <rect
        width="40"
        height="40"
        transform="matrix(1 0 0 -1 -5 36)"
        fill="#F3F3F5"
      />
      <mask
        id="descending1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="9"
        y="11"
        width="13"
        height="9"
      >
        <path
          d="M15.55 19.55L21.95 13.15L20.55 11.75L15.55 16.65L10.65 11.75L9.25 13.15L15.55 19.55Z"
          fill="black"
        />
      </mask>
      <g mask="url(#descending1)">
        <rect
          width="40"
          height="40"
          transform="matrix(0 1 1 0 -4.75 -4.25)"
          fill="#21B4EF"
        />
      </g>
    </g>
  </Svg>
);
