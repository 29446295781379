import * as React from 'react';

import { TrashSvg } from './style';

interface TrashProps {
  onClick?: VoidFunction;
  color?: string;
}

export const Trash = ({ onClick, color }: TrashProps) => (
  <TrashSvg
    width={21}
    height={24}
    fill="none"
    {...{ onClick }}
    style={{ marginTop: '2px' }}
  >
    <path d="M9 7H7v11h2V7zM14 7h-2v11h2V7z" fill={color || '#424249'} />
    <path
      d="M16 1V0H5v1H0v2h2v19.5c0 .8.7 1.5 1.5 1.5h14c.8 0 1.5-.7 1.5-1.5V3h2V1h-5zm1 21H4V3h13v19z"
      fill={color || '#424249'}
    />
  </TrashSvg>
);
