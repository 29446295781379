import styled from 'styled-components';

interface SvgProps {
  readonly height: string;
  readonly width: string;
  readonly inactive?: boolean;
}

export const Svg = styled.svg<SvgProps>`
  border-radius: 2px;
  padding: 0.3rem;
  z-index: 1;
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  ${({
    theme: {
      colors: { grey, primary },
    },
    inactive,
  }) =>
    inactive
      ? `
            path {
                fill: ${grey[200]};
            }
    `
      : `
            path {
                fill: ${primary.main};
            }
    &:hover {
        background-color: ${primary.lighter};
        path {
            fill: ${primary.light};
        }
    }
    &:active {
        fill: ${primary.dark};
        background-color: ${primary.lighter};
        path {
            fill: ${primary.dark};
        }
    }


    `}
`;
