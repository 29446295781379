import styled from 'styled-components';

interface SvgProps {
  variant?: number;
  bgOpacity?: number;
  borderColor?: string;
  shadow?: boolean;
  height: string | number;
  width: string | number;
  visibility: string;
  noHover?: boolean;
}

export const Svg = styled.svg<SvgProps>`
  visibility: ${({ visibility }) => visibility};
  border-radius: 100%;
  padding: 0.3rem;
  right: 1rem;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  ${({ noHover, bgOpacity }) =>
    !noHover &&
    `
	background-color: rgba(
    255,
    255,
    255,
    ${bgOpacity ?? 0.85}
  );
	`}

  ${({ borderColor }) =>
    borderColor ? `border: solid 1px ${borderColor};` : ''}
  ${({ shadow }) =>
    shadow
      ? `
        box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.15),
        0 5px 10px 0 rgba(0, 0, 0, 0.15);`
      : ''}

	&:hover {
    background-color: rgba(
      255,
      255,
      255,
      ${({ bgOpacity }) => bgOpacity ?? 0.85}
    );
    ${({ shadow }) =>
      shadow
        ? `
        box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.3);`
        : ''}
  }

  ${({ variant, theme }) =>
    variant === 2 &&
    `
		border-radius: 5px;
		font-weight: bold;
		path {
			fill: ${theme.colors.grey[0]};
		}
		background-color: rgba(
			0,
			0,
			0,
			0.3
		);

		&:hover {
			background-color: rgba(
				0,
				0,
				0,
				0.6
			);
			}
	`}

  ${({ variant, theme }) =>
    variant === 3 &&
    `
	border-radius: 5px;
	font-weight: bold;
	background: none;
	padding: 0;
	margin: 0;
	path {
		fill: ${theme.colors.primary.main};
	}

	&:hover {
		background-color: ${theme.colors.grey[200]};
	}
	&:active {
		background-color: ${theme.colors.grey[300]};
	}
`}

${({ noHover }) =>
    noHover &&
    `
		&:hover {
			background-color: transparent;
		}
		&:active {
			background-color: transparent;
		}
`}
`;
