import styled from 'styled-components';

interface SvgProps {
  height: string;
  width: string;
}

export const Svg = styled.svg<SvgProps>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  .a {
    fill: #fff;
  }
  .c {
    fill: #999;
  }
  .e {
    isolation: isolate;
  }
`;
