import {
  DropdownOption,
  MonitoringGetInvInteriorPageHeaderMenuMutation,
  useMonitoringGetInvInteriorPageHeaderMenuMutation,
} from 'interfaces/graphql.types';
import { useAuth } from 'modules/auth/providers/AuthProvider';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { OptionType } from 'renderer';

const enabledRoutes = ['/offering/[id]/investments'];

const useInvPageHeaderMenuPicker = () => {
  const {
    state: { loggedIn },
  } = useAuth();
  const router = useRouter();
  const [pageHeaderMenu, setPageHeaderMenu] = useState<DropdownOption>();
  const [pageHeaderMenuData, setPageHeaderMenuData] = useState<
    MonitoringGetInvInteriorPageHeaderMenuMutation | null | undefined
  >();

  const [getPageHeaderMenu, { loading: loadingPageHeaderMenu }] =
    useMonitoringGetInvInteriorPageHeaderMenuMutation();

  const isRoute = enabledRoutes.includes(router.pathname);

  useEffect(() => {
    if (loggedIn && isRoute) {
      getPageHeaderMenu({
        variables: {
          data: {},
        },
      })
        .then((result) => {
          setPageHeaderMenuData(result.data);
        })
        .catch(console.error);
    } else {
      setPageHeaderMenu(undefined);
    }
  }, [loggedIn, isRoute]);

  const onSelectChangePageHeaderMenu = (
    pageHeaderMenuOption?: OptionType | null | undefined
  ) => {
    if (pageHeaderMenuOption)
      setPageHeaderMenu({
        __typename: 'DropdownOption',
        name: pageHeaderMenuOption.label,
        pk: pageHeaderMenuOption.value || '',
      });
  };

  return {
    pageHeaderMenu,
    pageHeaderMenuData,
    loadingPageHeaderMenu,
    setPageHeaderMenu,
    setPageHeaderMenuData,
    onSelectChangePageHeaderMenu,
  };
};

export default useInvPageHeaderMenuPicker;
