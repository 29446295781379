import { Path, Svg } from './styles';

interface SubmissionsProps {
  height?: string;
  width?: string;
  strokeWidth?: string;
  color?: string;
  variant?: 'primary' | 'secondary';
}

export const Submissions = ({
  height = '3.75rem',
  width = '3rem',
  strokeWidth = '1',
  color,
  variant,
}: SubmissionsProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31 29"
    width={width}
    height={height}
  >
    <Path
      d="M13.6667 0.666504H3.00004C1.53337 0.666504 0.346708 1.8665 0.346708 3.33317L0.333374 24.6665C0.333374 26.1332 1.52004 27.3332 2.98671 27.3332H19C20.4667 27.3332 21.6667 26.1332 21.6667 24.6665V8.6665L13.6667 0.666504ZM3.00004 3.33317H12.3334L19 9.99984V21.4398L16.5467 18.9865C18.2534 16.3998 17.9734 12.8932 15.6934 10.6132C14.4 9.31984 12.7067 8.6665 11 8.6665C9.29337 8.6665 7.60004 9.31984 6.29337 10.6132C3.69337 13.2132 3.69337 17.4265 6.29337 20.0132C7.58671 21.3065 9.29337 21.9598 11 21.9598C12.28 21.9598 13.56 21.5865 14.6667 20.8532L18.4667 24.6665H3.00004V3.33317ZM13.8134 18.1332C13.0667 18.8798 12.0667 19.3065 11 19.3065C9.93337 19.3065 8.93337 18.8932 8.18671 18.1332C7.44004 17.3865 7.01337 16.3865 7.01337 15.3198C7.01337 14.2532 7.42671 13.2532 8.18671 12.5065C8.93337 11.7465 9.93337 11.3332 11 11.3332C12.0667 11.3332 13.0667 11.7465 13.8134 12.5065C14.56 13.2532 14.9867 14.2532 14.9867 15.3198C14.9867 16.3865 14.5734 17.3865 13.8134 18.1332Z"
      fill={color}
      variant={variant}
      strokeWidth={strokeWidth}
    />
  </Svg>
);
