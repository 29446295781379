import { LoaderIcon } from 'ui/Icons';

const Loader = (props: {
  toColor?: string;
  fromColor?: string;
  height?: string;
  width?: string;
  dPath?: string;
}) => <LoaderIcon {...props} />;

export default Loader;
