import { ElementType, MouseEvent } from 'react';

import { Svg } from './styles';

interface PenProps {
  style?: Record<string, unknown>;
  onClick?: (e: MouseEvent) => void;
  color?: string;
  height?: string;
  width?: string;
  strokeWidth?: string;
  inactive?: boolean;
  noHover?: boolean;
  as?: ElementType;
}

export const Pen = ({
  onClick,
  height = '24px',
  width = '24px',
  inactive = false,
  color = '#21B4EF',
  noHover,
  as,
}: PenProps) => (
  <Svg
    data-testid="pen-icon-id"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    inactive={inactive}
    onClick={inactive ? null : onClick}
    noHover={noHover}
    as={as}
  >
    <g clipPath="url(#clip0_18164_2133)">
      <path
        d="M14.06 9.02L14.98 9.94L5.92 19H5V18.08L14.06 9.02ZM17.66 3C17.41 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3ZM14.06 6.19L3 17.25V21H6.75L17.81 9.94L14.06 6.19Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_18164_2133">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
